<template>
    <main class="main main-successfulOrder">
        <div class="container checkout-container">
            <porto-loading-overlay v-if="loading"></porto-loading-overlay>
            <div class="row">
                <div class="col-lg-8 mx-auto px-0">
                    <div class="thankyou-order-recieved line-height-md text-center text-v-dark">
                        <i class="fas fa-check me-2"></i>
                        {{ $trans('Thank you. Your order has been received.') }}
                    </div>

                    <div class="order-info">
                        <div class="order order-item">
                            {{ $trans('Order Number') }}
                            <mark class="font-weight-bold order-number">{{ idString }}</mark>
                        </div>

                        <div class="status order-item">
                            {{ $trans('Status') }}
                            <mark class="font-weight-bold order-status text-turq">{{ $trans('orders.statuses.'+order.status) }}</mark>
                        </div>

                        <div class="date order-item">
                            {{ $trans('Date') }}
                            <mark class="font-weight-bold order-date">{{ $dateHelper.formatLaravelTimestampToDate(order.createdAt) }}</mark>
                        </div>

                        <div class="total order-item">
                            {{ $trans('Total') }}
                            <mark class="font-weight-bold order-total">
                                <span class="amount">
                                    {{ $priceFormatter.formatPriceText(order.grossTotal) }}
                                </span>
                            </mark>
                        </div>

                        <div class="method order-item">
                            {{ $trans('Payment method') }}
                            <mark class="font-weight-bold order-status">{{ order.payment_method.name }}</mark>
                        </div>
                    </div>

                    <div class="order-details mb-3" v-if="order.status === 'payment' || order.status === 'payInProgress'">
                        <div class="featured-boxes">
                            <div class="row">
                                <div class="col-12">
                                    <h4 class="card-sub-title text-md">{{ $trans('Pay now with card') }}</h4>
                                    <p><small>A fizetési folyamat elindításához kattints a kívánt szolgáltatóra</small></p>
                                    <a :href="'/payment/barion/start-payment/'+order.id" class="btn btn-barion">
                                        <img style="max-height: 20px; cursor: pointer" src="/storage/barion-logo.svg" alt="barion" title="Barion" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="order-details mb-3" v-if="order.payment_method.alias === 'bank_transfer' && (order.status === 'payment' || order.status === 'payInProgress')">

                        <div class="featured-boxes">
                            <div class="row">
                                <div class="col-12">
                                    <h4 class="card-sub-title text-md">{{ $trans('Pay with bank transfer') }}</h4>
                                    <table class="shop_table order_details text-v-dark">
                                        <tbody>
                                        <tr>
                                            <th>
                                                {{ $trans('Name') }}
                                            </th>
                                            <td>
                                                {{ bankName }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                {{ $trans('Account Number') }}
                                            </th>
                                            <td>
                                                {{ bankAccountNumber }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                {{ $trans('IBAN') }}
                                            </th>
                                            <td>
                                                {{ bankIban }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                {{ $trans('Swift') }}
                                            </th>
                                            <td>
                                                {{ bankSwift }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                {{ $trans('The amount to be transferred') }}
                                            </th>
                                            <td>
                                                {{ $priceFormatter.formatPriceText(order.grossTotal) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                {{ $trans('Statement') }}
                                            </th>
                                            <td>
                                                {{ order.proformaNumber }}
                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="order-details mb-3">

                        <div class="featured-boxes">
                            <h4 class="card-sub-title text-md">{{ $trans('Your order') }} <a class="btn btn-sm btn-primary text-white ml-3" @click="reorder(order.id)">Újrarendelés</a></h4>
                            <table class="shop_table order_details text-v-dark">
                                <thead>
                                <tr>
                                    <th class="product-name" colspan="2">{{ $trans('Product') }}</th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr class="order_item" v-for="item in order.items" :key="item.id">
                                    <td class="product-name">
                                        <a v-if="item.modelType === 'App\\Models\\Product'" :href="'/termek/'+item.model.slug+'-p'+item.model.id">{{ item.model.name }}</a>
                                        <a v-else :href="'/termekcsomag/'+item.model.slug+'-p'+item.model.id">{{ item.model.name }}</a>
                                        <strong class="product-quantity">×{{ parseInt(item.quantity) }}</strong>
                                    </td>

                                    <td class="product-total">
                                        <span class="amount">
                                            <bdi>{{ $priceFormatter.formatPriceText(item.grossTotal) }}</bdi>
                                        </span>
                                    </td>
                                </tr>

                                </tbody>

                                <tfoot>
                                <template v-for="condition in order.conditions">
                                    <tr v-if="condition.modelType === 'App\\Models\\Coupon'">
                                        <th scope="row"><h4 class="mb-0">{{ $trans('orderConditions.'+condition.modelType.replace('App\\Models\\', '')) }}</h4></th>
                                        <td>{{ condition.model.couponCode }}:
                                            <span v-if="condition.model.quantityType !== 'percent'" class="text-nowrap">{{ $priceFormatter.formatPriceText(condition.model.quantity) }}</span>
                                            <span v-if="condition.model.quantityType === 'percent'" class="text-nowrap">{{ parseInt(condition.model.quantity) }}%</span>
                                        </td>
                                    </tr>
                                </template>
                                <tr>
                                    <th scope="row"><h4 class="mb-0">{{ $trans('Subtotal') }}:</h4></th>
                                    <td><span class="amount">{{ $priceFormatter.formatPriceText(order.grossSubtotal) }}</span></td>
                                </tr>
                                <template v-for="condition in order.conditions">
                                    <tr v-if="condition.modelType !== 'App\\Models\\Coupon'">
                                        <th scope="row"><h4 class="mb-0">{{ $trans('orderConditions.'+condition.modelType.replace('App\\Models\\', '')) }}</h4></th>
                                        <td>{{ condition.model.name }}: <span class="text-nowrap">{{ $priceFormatter.formatPriceText(condition.grossTotal) }}</span></td>
                                    </tr>
                                </template>
                                <tr>
                                    <th scope="row"><h4 class="mb-0">{{ $trans('Comment for Boxfice') }}:</h4></th>
                                    <td><span class="amount">{{ (order.adminComment) ? order.adminComment : '-' }}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row"><h4 class="mb-0">{{ $trans('Comment for courier') }}:</h4></th>
                                    <td><span class="amount">{{ (order.courierComment) ? order.courierComment : '-' }}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row"><h4 class="mb-0">{{ $trans('Total') }}:</h4></th>
                                    <td><span class="amount">{{ $priceFormatter.formatPriceText(order.grossTotal) }}</span></td>
                                </tr>
                                </tfoot>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End .container -->
    </main>
    <!-- End .main -->
</template>

<script>

import stringHelper from "../helpers/stringHelper";

export default {
    props: {
        bankName: {
            type: String,
            required: true
        },
        bankAccountNumber: {
            type: String,
            required: true
        },
        bankIban: {
            type: String,
            required: true
        },
        bankSwift: {
            type: String,
            required: true
        }
    },
    created() {
        let url = new URL(document.URL)
        let params = new URLSearchParams(url.searchParams);
        this.id = atob(params.get('key'))
        this.initData()
    },
    data(){
        return {
            id: '',
            idString: '',
            order: {
                payment_method: ''
            },
            loading: false,
            shippingMethods: '',
            paymentMethods: ''
        }
    },
    methods: {
        initData(){
            this.loading = true

            this.$store.dispatch('fetchOrder', this.id).then(response => {
                this.order = response.data.entity
                this.idString = '#'+stringHelper.pad("0")(8)(this.id)
            }).finally(() => {
                this.loading = false
            })
        },
        reorder(id){
            this.$store.dispatch('reOrder', id).then(() => {
                window.location.href = '/penztar'
            })
        },
    }
}
</script>
