/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';
import state from './store/state';
import getters from './store/getters';
import actions from './store/boxfice/actions';
import mutations from './store/boxfice/mutations';
import {createApp} from 'vue';
import Vuex from "vuex";
import translationHelper from "./helpers/translationHelper";
import priceFormatter from "./formatters/priceFormatter";
import HeaderCart from './components/HeaderCart.vue';
import AddToCart from './components/AddToCart.vue';
import Cart from './components/Cart.vue';
import Checkout from "./components/Checkout.vue";
import AddToWishlist from "./components/AddToWishlist.vue";
import Wishlist from "./components/Wishlist.vue";
import PriceFilter from "./components/PriceFilter.vue";
import AddressEditor from "./components/AddressEditor.vue";
import VueTelInput from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import storageHelper from "./helpers/storageHelper";
import OrderFinalization from "./components/OrderFinalization.vue";
import SuccessfulOrder from "./components/SuccessfulOrder.vue";
import dateHelper from "./helpers/dateHelper";
import MyOrders from "./components/MyOrders.vue";
import AccountDetailEditor from "./components/AccountDetailEditor.vue";
import BarionBack from "./components/BarionBack.vue";
import MobileCartIcon from "./components/MobileCartIcon.vue";
import GlobalSearch from "./components/GlobalSearch.vue";
import AddedToCartModal from "./components/AddedToCartModal.vue"

const store = new Vuex.Store({
    state,
    actions,
    mutations,
    getters,
    strict: import.meta.env.DEV,
})

const app = createApp({});

const VueTelInputOptions = {
    mode: "international",
    autoFormat: true,
    autoDefaultCountry: true,
    inputOptions: {
        placeholder: '',
        type: 'text'
    },
    dropdownOptions: {
        showSearchBox: true
    }
}

app.use(store)
app.use({
    install() {
        app.trans = translationHelper
        app.storageHelper = storageHelper
        app.priceFormatter = priceFormatter
        app.dateHelper = dateHelper
    }
})
app.use(VueTelInput, VueTelInputOptions)

app.config.globalProperties.$trans = translationHelper;
app.config.globalProperties.$priceFormatter = priceFormatter;
app.config.globalProperties.$storageHelper = storageHelper;
app.config.globalProperties.$dateHelper = dateHelper;

app.component('header-cart', HeaderCart);
app.component('add-to-cart', AddToCart);
app.component('cart', Cart);
app.component('checkout', Checkout);
app.component('add-to-wishlist', AddToWishlist);
app.component('wishlist', Wishlist);
app.component('price-filter', PriceFilter);
app.component('address-editor', AddressEditor);
app.component('order-finalization', OrderFinalization);
app.component('successful-order', SuccessfulOrder);
app.component('my-orders', MyOrders);
app.component('account-detail-editor', AccountDetailEditor);
app.component('barion-back', BarionBack);
app.component('mobile-cart-icon', MobileCartIcon);
app.component('global-search', GlobalSearch);
app.component('added-to-cart-modal', AddedToCartModal)

app.mount('#app');
