<template>
    <i class="icon-shopping-cart position-relative">
        <span v-if="itemsCount > 0" class="cart-count badge-circle">{{ itemsCount }}</span>
    </i>
</template>

<script>
export default {
    created(){
        this.initData()
    },
    data(){
        return {
            items: '',
            itemsCount: 0,
        }
    },
    methods: {
        initData(){
            this.$store.dispatch('fetchCartItems').then(response => {
                this.items = response.data.entities
                this.itemsCount = Object.keys(this.items).length
            })
        },
    }
}
</script>
