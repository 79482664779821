<template>
    <div class="account-content">
        <Form ref="profileForm" @submit="submitForm" :validation-schema="formSchema" v-slot="{ errors }">
            <porto-loading-overlay v-if="loading"></porto-loading-overlay>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group" :class="{'has-error': errors.name}">
                        <label>{{ $trans('Name') }} <span class="required">*</span></label>
                        <Field name="name" as="input" class="form-control" v-model="formData.name"/>
                        <ErrorMessage class="form-error" name="name"/>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group" :class="{'has-error': errors.email}">
                        <label>{{ $trans('Email address') }} <span class="required">*</span></label>
                        <Field name="email" as="input" type="email" class="form-control" v-model="formData.email"/>
                        <ErrorMessage class="form-error" name="email"/>
                    </div>
                </div>
            </div>

            <div class="change-password">
                <h3 class="text-uppercase mb-2">{{ $trans('Password Change') }}</h3>


                <div class="form-group" :class="{'has-error': errors.password}">
                    <label>{{ $trans('New password') }} ({{ $trans('leave blank to leave unchanged') }})</label>
                    <Field name="password" as="input" type="password" class="form-control" v-model="formData.password" aria-autocomplete="newPassword" />
                    <ErrorMessage class="form-error" name="password"/>
                </div>

                <div class="form-group" :class="{'has-error': errors.passwordConfirmation}">
                    <label>{{ $trans('Confirm new password') }} ({{ $trans('leave blank to leave unchanged') }})</label>
                    <Field name="passwordConfirmation" as="input" type="password" class="form-control" v-model="formData.passwordConfirmation" aria-autocomplete="newPaswordConfirmation" />
                    <ErrorMessage class="form-error" name="passwordConfirmation"/>
                </div>
            </div>

            <div class="change-password">
                <h3 class="text-uppercase mb-2">{{ $trans('Subscribe newsletter') }}</h3>

                <p v-if="formData.isSubscribed">{{ $trans('You are subscribed.') }}</p>
                <p v-else>{{ $trans('You are not subscribed.') }}</p>

                <button v-if="formData.isSubscribed" type="button" class="btn btn-light" @click="unsubscribe">{{ $trans('Unsubscribe') }}</button>
                <button v-else type="button" class="btn btn-dark" @click="subscribe">{{ $trans('Subscribe') }}</button>

            </div>

            <div class="form-footer mt-3 mb-0">
                <button type="submit" class="btn btn-dark mr-0">
                    {{ $trans('Save Changes') }}
                </button>
            </div>
        </Form>
    </div>
</template>

<script>
import {Form, Field, ErrorMessage} from "vee-validate";
import PortoLoadingOverlay from "./PortoLoadingOverlay.vue";
import {setupVeeValidate} from "../utils/veeValidate";
import {registerLocale} from "i18n-iso-countries";

export default {
    components: {
        PortoLoadingOverlay,
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            formData: {
                name: '',
                email: '',
                password: '',
                passwordConfirmation: '',
                isSubscribed: ''
            },
            formSchema: {
                name: {
                    required: true,
                },
                email: {
                    required: true,
                    email: true,
                },
                password: {},
                passwordConfirmation: {},
            },
            loading: false,
        }
    },
    created() {
        setupVeeValidate();
    },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            this.$store.dispatch('initCurrentUser').then(() => {
                let user = this.$store.getters.user

                if (user) {
                    this.formData.name = user.name
                    this.formData.email = user.email
                    this.formData.isSubscribed = user.isSubscribed
                }
            })
        },
        submitForm() {
            let fd = JSON.parse(JSON.stringify(this.formData))

            this.$store.dispatch('updateCurrentUser', fd).then(() => {
                this.initData()
                Porto.miniPopup.open({
                    name: 'Siker!',
                    nameLink: '#',
                    imageSrc: '/img/success.png',
                    imageLink: '#',
                    content: 'A profil frissítése sikeres volt.',
                    action: '',
                });
            }).catch(error => {
                this.$refs.profileForm.setErrors(error.response.data.errors);
            }).finally(() => {

            })
        },
        subscribe(){
            this.$store.dispatch('subscribe').then(() => {
                this.initData()
                Porto.miniPopup.open({
                    name: 'Siker!',
                    nameLink: '#',
                    imageSrc: '/img/success.png',
                    imageLink: '#',
                    content: 'A feliratkozás sikeres volt.',
                    action: '',
                });
            }).catch(error => {
                Porto.miniPopup.open({
                    name: 'Hiba!',
                    nameLink: '#',
                    imageSrc: '/img/error.png',
                    imageLink: '#',
                    content: 'A feliratkozás nem sikerült. Kérlek próbáld újra vagy vedd fel velünk a kapcsolatot!',
                    action: '',
                });
            }).finally(() => {

            })
        },
        unsubscribe(){
            this.$store.dispatch('unsubscribe').then(() => {
                this.initData()
                Porto.miniPopup.open({
                    name: 'Siker!',
                    nameLink: '#',
                    imageSrc: '/img/success.png',
                    imageLink: '#',
                    content: 'A leiratkozás sikeres volt.',
                    action: '',
                });
            }).catch(error => {
                Porto.miniPopup.open({
                    name: 'Hiba!',
                    nameLink: '#',
                    imageSrc: '/img/error.png',
                    imageLink: '#',
                    content: 'A leiratkozás nem sikerült. Kérlek próbáld újra vagy vedd fel velünk a kapcsolatot!',
                    action: '',
                });
            }).finally(() => {

            })
        }
    }
}
</script>

<style scoped>

</style>
