import helpers from '../../../helpers'

const checkoutMutations = {
    SET_CHECKOUT_FORM_DATA(state, formData) {
        helpers.setLocalStorageItemWithExpiry('checkoutFormData', JSON.stringify(formData))
    },
    CLEAR_CHECKOUT_FORM_DATA(state) {
        helpers.deleteLocalStorageItem('checkoutFormData')
    },
}

export default checkoutMutations
