import hu from '../../../lang/hu.json'

const translationHelper = function (string, params = {}){
    const html = document.documentElement
    let currentLanguage = html.getAttribute('lang')
    const languages = {
        hu: hu
    }

    if(Object.keys(languages[currentLanguage]).includes(string)){

        let translatedString = languages[currentLanguage][string]

        if(Object.keys(params).length > 0){

            Object.keys(params).forEach(key => {
                if(translatedString.includes(':'+key)){
                    translatedString = translatedString.replace(':'+key, params[key])
                }
            })
        }
        return translatedString
    }

    return string
}

export default translationHelper
