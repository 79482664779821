<template>
    <div class="header-icon header-search header-search-inline header-search-category w-lg-max text-right mt-0">
        <a class="search-toggle" role="button"><i class="icon-search-3"></i></a>
        <form>
            <div class="header-search-wrapper">
                <input @focusin="showSearchOverlay" type="search" class="form-control" v-model="searchTerm" name="q" id="q"
                       :placeholder="$trans('Search...')"
                       required>
                <button class="btn icon-magnifier p-0" :title="$trans('Search')"
                        type="submit"></button>
            </div>
            <!-- End .header-search-wrapper -->
        </form>
        <div class="header-global-search-result" :class="{'visible': searchOverlayIsVisible}">
            <div class="form-wrapper">
                <a class="close-btn" @click="hideSearchOverlay">&times;</a>
                <form class="d-flex align-items-center justify-content-center">
                <input ref="searchInput" type="search" @input="search" class="form-control" v-model="searchTerm" name="q" id="q"
                       :placeholder="$trans('Search...')"
                       required>
                </form>
            </div>
            <div class="search-result-wrapper pt-5">
                <div class="row" v-if="searchTerm.length > 2">
                    <div class="col-sm-12 col-lg-4">
                        <h3 class="section-title heading-border ls-20 border-0">{{ $trans('Products') }}</h3>

                        <div class="card search-card" v-for="index in 3" :key="index" v-if="loading">
                            <div class="card-body">
                                <div class="media">
                                    <div class="skeleton skeleton-image mr-3"></div>
                                    <div class="media-body">
                                        <h5 class="mt-0 skeleton skeleton-header skeleton-text"></h5>
                                        <div class="skeleton-wrapper">
                                            <div class="skeleton-rows">
                                                <p class="skeleton skeleton-text skeleton-text__body"></p>
                                                <p class="skeleton skeleton-text skeleton-text__body"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5 class="text-left mt-0" v-if="!loading && !products.length">{{ $trans('Search result not found') }}</h5>

                        <div class="card search-card" v-for="product in products" :key="product.id" v-if="!loading">
                            <div class="card-body">
                                <div class="media">
                                    <a :href="'/termek/'+product.slug+'-p'+product.id">
                                        <img class="mr-3" :src="product.mainImage" :alt="product.name" width="40" height="40" />
                                    </a>
                                    <div class="media-body">
                                        <div>
                                            <h5 class="text-left mt-0 mb-0"><a :href="'/termek/'+product.slug+'-p'+product.id">{{ product.name }}</a></h5>
                                            <div class="price-box">
                                            <span v-if="product.salePrice > 0 && product.salePrice !== product.price && product.salePrice < product.price">
                                                <span class="old-price">{{ $priceFormatter.formatPriceText(product.grossPrice) }}</span>
                                            <span class="product-price">{{ $priceFormatter.formatPriceText(product.grossSalePrice) }}</span>
                                            </span>
                                                <span v-else class="product-price">{{ $priceFormatter.formatPriceText(product.grossPrice) }}</span>
                                            </div><!-- End .price-box -->
                                        </div>
                                        <add-to-cart v-if="product.status === 'active'" :model="product" type="product" variant="mini"></add-to-cart>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <a v-if="!loading && categories.length" :href="'/termekek?q='+searchTerm"
                           class="btn btn-primary btn-sm text-white btn-more float-right">További találatok megtekintése</a>
                    </div>

                    <div class="col-sm-12 col-lg-4">
                        <h3 class="section-title heading-border ls-20 border-0">{{ $trans('Categories') }}</h3>

                        <div class="card search-card" v-for="index in 3" :key="index" v-if="loading">
                            <div class="card-body">
                                <div class="media">
                                    <div class="skeleton skeleton-image mr-3"></div>
                                    <div class="media-body">
                                        <h5 class="mt-0 skeleton skeleton-header skeleton-text"></h5>
                                        <div class="skeleton-wrapper">
                                            <div class="skeleton-rows">
                                                <p class="skeleton skeleton-text skeleton-text__body"></p>
                                                <p class="skeleton skeleton-text skeleton-text__body"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5 class="text-left mt-0" v-if="!loading && !categories.length">{{ $trans('Search result not found') }}</h5>

                        <div class="card search-card" v-for="category in categories" :key="category.id" v-if="!loading">
                            <div class="card-body">
                                <div class="media">
                                    <a :href="'/termekkategoria/'+category.slug+'-c'+category.id">
                                        <img v-if="category.imageId" class="mr-3" :src="category.imageUrl" :alt="category.name" width="40" height="40" />
                                        <img v-else class="mr-3" src="/assets/images/placeholder-image-500-500.png" :alt="category.name" width="40" height="40" />
                                    </a>
                                    <div class="media-body">
                                        <h5 class="text-left mt-0"><a :href="'/termekkategoria/'+category.slug+'-c'+category.id">{{ category.name }}</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-lg-4">
                        <h3 class="section-title heading-border ls-20 border-0">{{ $trans('Manufacturers') }}</h3>

                        <div class="card search-card" v-for="index in 3" :key="index" v-if="loading">
                            <div class="card-body">
                                <div class="media">
                                    <div class="skeleton skeleton-image mr-3"></div>
                                    <div class="media-body">
                                        <h5 class="mt-0 skeleton skeleton-header skeleton-text"></h5>
                                        <div class="skeleton-wrapper">
                                            <div class="skeleton-rows">
                                                <p class="skeleton skeleton-text skeleton-text__body"></p>
                                                <p class="skeleton skeleton-text skeleton-text__body"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5 class="text-left mt-0" v-if="!loading && !manufacturers.length">{{ $trans('Search result not found') }}</h5>

                        <div class="card search-card" v-for="manufacturer in manufacturers" :key="manufacturer.id" v-if="!loading">
                            <div class="card-body">
                                <div class="media">
                                    <a :href="'/marka/'+manufacturer.slug+'-b'+manufacturer.id">
                                        <img v-if="manufacturer.imageId" class="mr-3" :src="manufacturer.imageUrl" :alt="manufacturer.name" width="40" height="40" />
                                        <img v-else class="mr-3" src="/assets/images/placeholder-image-500-500.png" :alt="manufacturer.name" width="40" height="40" />
                                    </a>
                                    <div class="media-body">
                                        <h5 class="text-left mt-0"><a :href="'/marka/'+manufacturer.slug+'-b'+manufacturer.id">{{ manufacturer.name }}</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="false">
                    <div class="col-sm-12 col-lg-4">
                        <h3 class="section-title heading-border ls-20 border-0">{{ $trans('Product packages') }}</h3>

                        <div class="card search-card" v-for="index in 3" :key="index" v-if="loading">
                            <div class="card-body">
                                <div class="media">
                                    <div class="skeleton skeleton-image mr-3"></div>
                                    <div class="media-body">
                                        <h5 class="mt-0 skeleton skeleton-header skeleton-text"></h5>
                                        <div class="skeleton-wrapper">
                                            <div class="skeleton-rows">
                                                <p class="skeleton skeleton-text skeleton-text__body"></p>
                                                <p class="skeleton skeleton-text skeleton-text__body"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-4" >
                        <h3 class="section-title heading-border ls-20 border-0">{{ $trans('Product package categories') }}</h3>

                        <div class="card search-card" v-for="index in 3" :key="index" v-if="loading">
                            <div class="card-body">
                                <div class="media">
                                    <div class="skeleton skeleton-image mr-3"></div>
                                    <div class="media-body">
                                        <h5 class="mt-0 skeleton skeleton-header skeleton-text"></h5>
                                        <div class="skeleton-wrapper">
                                            <div class="skeleton-rows">
                                                <p class="skeleton skeleton-text skeleton-text__body"></p>
                                                <p class="skeleton skeleton-text skeleton-text__body"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-4">
                        <h3 class="section-title heading-border ls-20 border-0">{{ $trans('Posts') }}</h3>

                        <div class="card search-card" v-for="index in 3" :key="index" v-if="loading">
                            <div class="card-body">
                                <div class="media">
                                    <div class="skeleton skeleton-image mr-3"></div>
                                    <div class="media-body">
                                        <h5 class="mt-0 skeleton skeleton-header skeleton-text"></h5>
                                        <div class="skeleton-wrapper">
                                            <div class="skeleton-rows">
                                                <p class="skeleton skeleton-text skeleton-text__body"></p>
                                                <p class="skeleton skeleton-text skeleton-text__body"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            searchTerm: '',
            searchOverlayIsVisible: false,
            products: [],
            categories: [],
            manufacturers: [],
            productPackages: [],
            productPackageCategories: [],
            posts: []
        }
    },
    methods: {
        showSearchOverlay() {
            this.searchOverlayIsVisible = true
            document.querySelector('body').style.overflow = 'hidden'
            this.$refs['searchInput'].focus()
        },
        hideSearchOverlay() {
            this.searchOverlayIsVisible = false
        },
        search() {
            if(this.searchTerm.length > 2) {
                this.loading = true

                this.$store.dispatch('globalSearch', {
                    q: this.searchTerm,
                    limit: 3
                }).then(response => {
                    this.products = response.data.products
                    this.categories = response.data.categories
                    this.manufacturers = response.data.manufacturers
                }).catch(() => {

                }).finally(() => {
                    this.loading = false
                })
            }
        }
    },
}
</script>
