const authActions = {
    getCurrentUser() {
        return window.axios.get('/me')
    },
    updateCurrentUser({commit, dispatch}, data) {
        return window.axios.post('/updateProfile', data)
    },
    subscribe() {
        return window.axios.post('/subscribe')
    },
    unsubscribe() {
        return window.axios.post('/unsubscribe')
    },
    initCurrentUser({commit, dispatch}) {
        return new Promise((resolve, reject) => {
            dispatch('getCurrentUser').then(response => {
                commit('SET_USER_DATA', response.data.data)
                resolve(response.data)
            })
                .catch(error => {
                    commit('CLEAR_USER_DATA')
                    reject(error)
                })
        })
    }
}

export default authActions
