<template>
    <div class="order-table-container text-center">
        <porto-loading-overlay v-if="loading"></porto-loading-overlay>
        <table class="table table-order text-left">
            <thead>
            <tr>
                <th class="order-id">{{ $trans('Order Number') }}</th>
                <th class="order-date">{{ $trans('Date') }}</th>
                <th class="order-status">{{ $trans('Status') }}</th>
                <th class="order-price">{{ $trans('Total') }}</th>
                <th class="order-action">{{ $trans('Actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in entities">
                <td>{{ row.idString }}</td>
                <td>{{ $dateHelper.formatLaravelTimestampToDate(row.createdAt) }}</td>
                <td>{{ $trans('orders.statuses.' + row.status) }}</td>
                <td>{{ $priceFormatter.formatPriceText(row.grossTotal) }}</td>
                <td class="btn-actions">
                    <a class="btn btn-sm btn-primary"
                       :href="'/sikeres-megrendeles?key='+ row.encodedKey">Megtekintés</a>
                    <a class="btn btn-sm btn-secondary ml-3" @click="reorder(row.id)">Újrarendelés</a>
                </td>
            </tr>
            <tr v-if="entities.length === 0">
                <td class="text-center p-0" colspan="5">
                    <p class="mb-5 mt-5">
                        {{ $trans('No Order has been made yet.') }}
                    </p>
                </td>
            </tr>
            </tbody>
        </table>

        <ul class="d-flex align-items-center justify-content-center mt-2 pagination pagination-history">
            <li
                v-for="page in paginator"
                :key="page"
                class="page-item"
                :class="{active: page.active, disabled: (currentPage === 1 && page.label === 'pagination.previous') || (currentPage === lastPage && page.label === 'pagination.next')}"
                @click="paginate(page.label)">

                <a class="page-link" href="javascript:void(0);">
                    <span v-if="page.label !== 'pagination.previous' && page.label !== 'pagination.next'">
                        {{ page.label }}
                    </span>
                    <span v-if="page.label === 'pagination.previous'">
                        {{ $trans('Previous') }}
                    </span>
                    <span v-if="page.label === 'pagination.next'">
                        {{ $trans('Next') }}
                    </span>
                </a>
            </li>
        </ul>

        <div class="text-center">Összesen: <strong>{{ total }}</strong> sor</div>

        <hr class="mt-0 mb-3 pb-2"/>

        <a href="/termekek" class="btn btn-dark">{{ $trans('Go Shop') }}</a>
    </div>
</template>

<script>

import PortoLoadingOverlay from "./PortoLoadingOverlay.vue";
import stringHelper from "../helpers/stringHelper";

export default {
    components: {
        PortoLoadingOverlay
    },
    data() {
        return {
            currentPage: 1,
            firstPage: 1,
            lastPage: 1,
            perPage: 10,
            total: 0,
            entities: [],
            paginator: '',
            loading: false,
            sortBy: {
                field: 'id',
                type: 'asc',
            }
        }
    },
    created() {
        this.initData()
    },
    methods: {
        initData() {
            this.loading = true
            const searchParams = {
                page: parseInt(this.currentPage),
                limit: parseInt(this.perPage),
                query: null,
                columnFilters: [],
                sort: {
                    field: 'id',
                    type: 'desc'
                },
            }

            this.$store.dispatch('fetchOrders', searchParams).then(response => {
                this.currentPage = response.data.entities.current_page
                this.firstPage = 1
                this.lastPage = response.data.entities.last_page
                this.perPage = response.data.entities.per_page
                this.total = response.data.entities.total
                this.entities = response.data.entities.data
                this.paginator = response.data.entities.links

                Object.keys(this.entities).forEach(key => {
                    this.entities[key].encodedKey = btoa(this.entities[key].id)
                    this.entities[key].idString = '#' + stringHelper.pad("0")(8)(this.entities[key].id)
                })
            }).finally(() => {
                this.loading = false
            })
        },
        paginate(page) {
            if (page === 'pagination.previous') {
                this.currentPage--
            } else if (page === 'pagination.next') {
                this.currentPage++
            } else {
                this.currentPage = page
            }
            this.initData()
        },
        reorder(id){
            this.$store.dispatch('reOrder', id).then(() => {
                window.location.href = '/penztar'
            })
        },
        deleteOrder(id){
            this.$store.dispatch('deleteOrder', id).then(() => {
                this.initData()
                Porto.miniPopup.open({
                    name: 'Siker!',
                    nameLink: '#',
                    imageSrc: '/img/success.png',
                    imageLink: '#',
                    content: 'A rendelés törlése sikeres volt.',
                    action: '',
                });
            })
        }
    }
}
</script>

<style scoped>
    .btn-danger{
        color: #ffffff !important;
    }
</style>
