export default {
    pad(char) {
        return function (len) {
            let prefix = padder(len);
            return function (str) {
                return (str == null) ? prefix : (prefix + str).substr(-len);
            };
        };

        function padder(len) {
            let array = [];
            for (let i = 0; i < len; i++) {
                array[i] = char;
            }
            return array.join("");
        }
    }
}
