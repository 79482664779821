<template>
    <div class="dropdown cart-dropdown">
        <a href="javascript:void(0);" :title="$trans('Cart')" class="dropdown-toggle dropdown-arrow cart-toggle"
           role="button"
           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
            <i class="minicart-icon"></i>
            <span v-if="itemsCount > 0" class="cart-count badge-circle">{{ itemsCount }}</span>
        </a>

        <div class="cart-overlay"></div>

        <div class="dropdown-menu mobile-cart">
            <a href="javascript:void(0);" :title="$trans('Close (Esc)')" class="btn-close">×</a>

            <div class="dropdownmenu-wrapper custom-scrollbar">
                <div class="dropdown-cart-header">{{ $trans('Cart') }}</div>

                <div class="alert alert-warning" v-if="subtotal < minimumOrderValue">
                    Webáruházunkban a minimális rendelési érték {{ $priceFormatter.formatPriceText(minimumOrderValue) }}. A kosaradban lévő termékek összértéke nem éri el ezt az összeget, kérlek vásárolj még {{ $priceFormatter.formatPriceText(minimumOrderValue-subtotal) }} értékben.
                </div>

                <!-- End .dropdown-cart-header -->
                <div v-if="itemsCount > 0" class="dropdown-cart-products">
                    <div v-for="item in items" :key="item.id" class="product">
                        <div class="product-details">
                            <h4 class="product-title">
                                <a v-if="item.associatedModel.modelType === 'App\\Models\\Product'" :href="'/termek/'+item.associatedModel.slug+'-p'+item.associatedModel.id">{{ item.name }}</a>
                                <a v-else :href="'/termekcsomag/'+item.associatedModel.slug+'-p'+item.associatedModel.id">{{ item.name }}</a>
                            </h4>

                            <span class="cart-product-info">
													<span class="cart-product-qty">{{ item.quantity }}</span> × {{ $priceFormatter.formatPriceText(item.price) }}
                                                </span>
                        </div>
                        <!-- End .product-details -->

                        <figure class="product-image-container">
                            <a v-if="item.associatedModel.modelType === 'App\\Models\\Product'" :href="'/termek/'+item.associatedModel.slug+'-p'+item.associatedModel.id" class="product-image">
                                <img :src="item.associatedModel.mainImage"
                                     alt="product" width="80" height="80">
                            </a>
                            <a v-else :href="'/termekcsomag/'+item.associatedModel.slug+'-p'+item.associatedModel.id" class="product-image">
                                <img :src="item.associatedModel.mainImage"
                                     alt="product" width="80" height="80">
                            </a>

                            <a href="javascript:void(0);" @click="removeFromCart(item.associatedModel)" class="btn-remove" :title="$trans('Remove Product')"><span>×</span></a>
                        </figure>
                    </div>
                </div>
                <!-- End .cart-product -->


                <div v-if="itemsCount > 0" class="dropdown-cart-total">
                    <span>{{ $trans('Subtotal').toUpperCase() }}:</span>

                    <span class="cart-total-price float-right">{{ $priceFormatter.formatPriceText(subtotal) }}</span>
                </div>
                <!-- End .dropdown-cart-total -->

                <div v-if="itemsCount > 0" class="dropdown-cart-action">
                    <a href="/kosar" class="btn btn-gray btn-block view-cart">{{ $trans('View cart') }}</a>
                    <a href="/penztar" class="btn btn-dark btn-block" :class="{'disabled pointer-events-none': subtotal < minimumOrderValue}">{{ $trans('Go to checkout') }}</a>
                </div>
                <!-- End .dropdown-cart-total -->
                <strong v-else class="subtitle empty">{{ $trans('You have no items in your shopping cart.') }}</strong>
            </div>
            <!-- End .dropdownmenu-wrapper -->
        </div>
        <!-- End .dropdown-menu -->
    </div>
    <!-- End .dropdown -->
</template>

<script>
    export default {
        created(){
            this.initData()
        },
        props: {
            minimumOrderValue: {
                type: String,
                required: true
            }
        },
        data(){
            return {
                items: '',
                itemsCount: 0,
                subtotal: 0,
            }
        },
        methods: {
            initData(){
                this.$store.dispatch('fetchCartItems').then(response => {
                    this.items = response.data.entities
                    this.subtotal = response.data.subtotal
                    this.itemsCount = Object.keys(this.items).length
                })
            },
            removeFromCart(product){
                let quantity = 1
                Object.keys(this.items).forEach(key => {
                    if(this.items[key].id === product.id){
                        quantity = this.items[key].quantity
                    }
                })

                this.$store.dispatch('removeFromCart', {id: product.id, type: product.modelType}).then(() => {
                    this.initData()
                    Porto.miniPopup.open( {
                        name: product.name,
                        nameLink: '/termek'+product.slug+'/-p'+product.id,
                        imageSrc: product.mainImage,
                        imageLink: '/termek'+product.slug+'/-p'+product.id,
                        content: this.$trans('has been removed from your cart.'),
                        action: '<a href="/kosar" class="btn viewcart">'+this.$trans('View cart')+'</a>',
                    } );

                    let price = product.price
                    if(product.salePrice > 0){
                        price = product.salePrice
                    }

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'remove_from_cart',
                        ecommerce: {
                            // TODO: ha lesz több valuta, tegyük dinamikussá
                            currency: 'HUF',
                            value: this.$priceFormatter.formatPriceNumber(quantity*price*(1+(product.taxRate/100))),
                            items: [
                                {
                                    item_name: product.name,
                                    price: this.$priceFormatter.formatPriceNumber(price*(1+(product.taxRate/100))),
                                    item_category: product.category.name,
                                    quantity: quantity
                                },
                            ]
                        }
                    })

                }).catch(error => {
                    if (error.response.status === 500) {
                        Porto.miniPopup.open( {
                            name: 'Hiba!',
                            nameLink: '#',
                            imageSrc: '/img/error.png',
                            imageLink: '#',
                            content: error.response.data.message,
                            action: '',
                        } );
                    }
                })
            }
        }
    }
</script>
