<template>
    <div ref="porto-loading-overlay" class="porto-loading-overlay">
        <div class="bounce-loader">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        let parent = this.$refs['porto-loading-overlay'].parentElement;
        let child = this.$refs['porto-loading-overlay'].firstElementChild;
        parent.style.position = 'relative'
    }
}
</script>

<style scoped>
.porto-loading-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    z-index: 99;
}
</style>
