import AllRules from '@vee-validate/rules';
import {configure, defineRule} from "vee-validate";
import {localize, setLocale} from "@vee-validate/i18n";
import hu from './hu.json';
import translationHelper from "../../helpers/translationHelper";
import {parsePhoneNumber} from 'awesome-phonenumber'

export const setupVeeValidate = function () {
    Object.keys(AllRules).forEach(rule => {
        defineRule(rule, AllRules[rule]);
    });

    defineRule('phone', (value) => {

        let phone = parsePhoneNumber(value.toString());

        if (!value || !value.length) {
            return true;
        }

        return !(!phone.valid);
    });

    defineRule('taxNumber', (value, [cc]) => {

        if (typeof cc == "undefined") {
            cc = 'HU'
        }

        if (!value || typeof value == "undefined") {
            return true
        }

        if (!value.length || !cc.length) {
            return true
        }

        const regexps = {
            'AT': /^(U\d{8}$)/,                                   // Austria
            'BE': /^(\d{10}$)/,                                   // Belgium
            'BG': /^(\d{9,10}$)/,                                 // Bulgaria
            'CY': /^([0-5|9]\d{7}[A-Z]$)/,                        // Cyprus
            'CZ': /^(\d{8,10})?$/,                                // Czech Republic
            'DE': /^([1-9]\d{8}$)/,                               // Germany
            'DK': /^(\d{8}$)/,                                    // Denmark
            'EE': /^(10\d{7}$)/,                                  // Estonia
            'EL': /^(\d{9}$)/,                                    // Greece
            'ES': /^([0-9A-Z][0-9]{7}[0-9A-Z]$)/,                 // Spain
            'EU': /^(\d{9}$)/,                                    // EU-type
            'FI': /^(\d{8}$)/,                                    // Finland
            'FR': /^([0-9A-Z]{2}[0-9]{9}$)/,                      // France
            'GB': /^((?:[0-9]{12}|[0-9]{9}|(?:GD|HA)[0-9]{3})$)/, // UK (Standard = 9 digits), (Branches = 12 digits), (Government = GD + 3 digits), (Health authority = HA + 3 digits)
            'GR': /^(\d{8,9}$)/,                                  // Greece
            'HR': /^(\d{11}$)/,                                   // Croatia
            'HU': /^[0-9]{8}-[0-9]-[0-9]{2}$/,                    // Hungary
            'IE': /^([0-9A-Z\*\+]{7}[A-Z]{1,2}$)/,                // Ireland
            'IT': /^(\d{11}$)/,                                   // Italy
            'LV': /^(\d{11}$)/,                                   // Latvia
            'LT': /^(\d{9}$|\d{12}$)/,                            // Lithunia
            'LU': /^(\d{8}$)/,                                    // Luxembourg
            'MT': /^([1-9]\d{7}$)/,                               // Malta
            'NL': /^(\d{9}B\d{2}$)/,                              // Netherlands
            'NO': /^(\d{9}$)/,                                    // Norway (not EU)
            'PL': /^(\d{10}$)/,                                   // Poland
            'PT': /^(\d{9}$)/,                                    // Portugal
            'RO': /^([1-9]\d{1,9}$)/,                             // Romania
            'RU': /^(\d{10}$|\d{12}$)/,                           // Russia
            'RS': /^(\d{9}$)/,                                    // Serbia
            'SI': /^([1-9]\d{7}$)/,                               // Slovenia
            'SK': /^([1-9]\d[(2-4)|(6-9)]\d{7}$)/,                // Slovakia Republic
            'SE': /^(\d{10}01$)/                                  // Sweden
        }

        if (Object.keys(regexps).includes(cc)) {
            const taxNumberRegex = regexps[cc]
            return taxNumberRegex.test(value)
        }

        return false
    })

    defineRule('terms', (value) => {
        if (typeof value == "undefined") {
            return false
        }
        return value
    })

    configure({
        validateOnBlur: true,
        validateOnChange: true,
        validateOnInput: true,
        validateOnModelUpdate: true,
        generateMessage: localize(hu.code, {
            messages: hu.messages,
            names: {
                name: translationHelper('Name').toLowerCase(),
                email: translationHelper('Email address').toLowerCase(),
                phone: translationHelper('Phone').toLowerCase(),
                taxNumber: translationHelper('Tax number').toLowerCase(),
                companyName: translationHelper('Company name').toLowerCase(),
                country: translationHelper('Country').toLowerCase(),
                zip: translationHelper('Zip').toLowerCase(),
                city: translationHelper('City').toLowerCase(),
                street: translationHelper('Street').toLowerCase(),
                houseNumber: translationHelper('House number').toLowerCase(),
                houseNumberInfo: translationHelper('House number info').toLowerCase(),

                billingName: translationHelper('Name').toLowerCase(),
                billingEmail: translationHelper('Email address').toLowerCase(),
                billingPhone: translationHelper('Phone').toLowerCase(),
                billingTaxNumber: translationHelper('Tax number').toLowerCase(),
                billingCompanyName: translationHelper('Company name').toLowerCase(),
                billingCountry: translationHelper('Country').toLowerCase(),
                billingZip: translationHelper('Zip').toLowerCase(),
                billingCity: translationHelper('City').toLowerCase(),
                billingStreet: translationHelper('Street').toLowerCase(),
                billingHouseNumber: translationHelper('House number').toLowerCase(),
                billingHouseNumberInfo: translationHelper('House number info').toLowerCase(),

                "billingAddress.name": translationHelper('Name').toLowerCase(),
                "billingAddress.email": translationHelper('Email address').toLowerCase(),
                "billingAddress.phone": translationHelper('Phone').toLowerCase(),
                "billingAddress.taxNumber": translationHelper('Tax number').toLowerCase(),
                "billingAddress.companyName": translationHelper('Company name').toLowerCase(),
                "billingAddress.country": translationHelper('Country').toLowerCase(),
                "billingAddress.zip": translationHelper('Zip').toLowerCase(),
                "billingAddress.city": translationHelper('City').toLowerCase(),
                "billingAddress.street": translationHelper('Street').toLowerCase(),
                "billingAddress.houseNumber": translationHelper('House number').toLowerCase(),
                "billingAddress.houseNumberInfo": translationHelper('House number info').toLowerCase(),

                shippingName: translationHelper('Name').toLowerCase(),
                shippingEmail: translationHelper('Email address').toLowerCase(),
                shippingPhone: translationHelper('Phone').toLowerCase(),
                shippingCountry: translationHelper('Country').toLowerCase(),
                shippingZip: translationHelper('Zip').toLowerCase(),
                shippingCity: translationHelper('City').toLowerCase(),
                shippingStreet: translationHelper('Street').toLowerCase(),
                shippingHouseNumber: translationHelper('House number').toLowerCase(),
                shippingHouseNumberInfo: translationHelper('House number info').toLowerCase(),

                "shippingAddress.name": translationHelper('Name').toLowerCase(),
                "shippingAddress.email": translationHelper('Email address').toLowerCase(),
                "shippingAddress.phone": translationHelper('Phone').toLowerCase(),
                "shippingAddress.country": translationHelper('Country').toLowerCase(),
                "shippingAddress.zip": translationHelper('Zip').toLowerCase(),
                "shippingAddress.city": translationHelper('City').toLowerCase(),
                "shippingAddress.street": translationHelper('Street').toLowerCase(),
                "shippingAddress.houseNumber": translationHelper('House number').toLowerCase(),
                "shippingAddress.houseNumberInfo": translationHelper('House number info').toLowerCase(),
            }
        }),
    });
    setLocale('hu');
}
