// eslint-disable-next-line import/no-cycle
import userMutations from './userMutations'
import checkoutMutations from "./checkoutMutations";

const mutations = {
    ...userMutations,
    ...checkoutMutations,
}

export default mutations
