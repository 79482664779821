const orderActions = {
    fetchOrders({commit, dispatch}, searchParams) {
        return window.axios.get('/orders', {params: searchParams})
    },
    storeOrder({commit, dispatch}, data) {
        return window.axios.post('/order', data)
    },
    fetchOrder({ commit, dispatch }, id) {
        return window.axios.get(`/order/${id}`)
    },
    reOrder({ commit, dispatch }, id) {
        return window.axios.post(`/order/reorder/${id}`)
    },
    deleteOrder({ commit, dispatch }, id) {
        return window.axios.delete(`/order/${id}`)
    },
}

export default orderActions
