import cryptHelper from './cryptHelper'

const storageHelper = {
    getLocalStorageItem(key) {
        const data = localStorage.getItem(key)

        if (data) {
            return cryptHelper.sysDecrypt(data)
        }

        return false
    },
    getLocalStorageItemWithExpiry(key) {
        const data = localStorage.getItem(key)

        if (data) {
            const item = JSON.parse(data)
            const now = new Date()
            if (now.getTime() > item.expiry) {
                localStorage.removeItem(key)
                return null
            }

            return item.data
        }

        return false
    },
    setLocalStorageItemWithExpiry(key, data, ttl = 7200000) {

        const now = new Date()

        const item = {
            data: data,
            expiry: now.getTime() + ttl,
        }

        return localStorage.setItem(key, JSON.stringify(item))
    },
    setLocalStorageItem(key, data) {
        return localStorage.setItem(key, cryptHelper.sysEncrypt(data))
    },
    deleteLocalStorageItem(key) {
        localStorage.removeItem(key)
    },
}

export default storageHelper
