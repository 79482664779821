<template>
    <form>
        <div class="price-slider-wrapper">
            <div id="price-slider"></div>
            <!-- End #price-slider -->
        </div>
        <!-- End .price-slider-wrapper -->

        <div class="filter-price-action d-flex align-items-center justify-content-between flex-wrap">
            <div class="filter-price-text">
                {{ $trans('Price') }}:
                <span id="filter-price-range"></span>
            </div>
            <!-- End .filter-price-text -->

            <button type="submit" @click.prevent="submitForm" class="btn btn-primary">{{ $trans('Filtering') }}</button>
        </div>
        <!-- End .filter-price-action -->
    </form>
</template>

<script>
export default {
    props: {
        priceMin: {
            required: true
        },
        priceMax: {
            required: true
        },
        queriedPriceMin: {
            required: true
        },
        queriedPriceMax: {
            required: true
        }
    },
    data() {
        return {
            step: 100,
            margin: 100,
            selectedPriceMin: 0,
            selectedPriceMax: 0
        }
    },
    mounted() {
        let _this = this
        window.setTimeout(function () {
            _this.initSlider()
        }, 800);
    },
    methods: {
        initSlider() {
            let priceSlider = document.getElementById('price-slider')

            if (priceSlider == null) return;

            let priceFormatter = this.$priceFormatter

            let startMin = this.queriedPriceMin
            let startMax = this.queriedPriceMax

            if (startMin === "") {
                startMin = this.priceMin
            }
            if (startMax === "") {
                startMax = this.priceMax
            }

            window.noUiSlider.create(priceSlider, {
                start: [parseFloat(startMin), parseFloat(startMax)],
                connect: true,
                step: this.step,
                margin: this.margin,
                range: {
                    'min': parseFloat(this.priceMin),
                    'max': parseFloat(this.priceMax)
                }
            });

            let _this = this

            // Update Price Range
            priceSlider.noUiSlider.on('update', function (values, handle) {

                _this.selectedPriceMin = values[0]
                _this.selectedPriceMax = values[1]

                let newValues = values.map(function (value) {
                    return priceFormatter.formatPriceText(parseFloat(value));
                })

                $('#filter-price-range').text(newValues.join(' - '));
            });

        },
        submitForm() {
            let currentUrl = window.location.href;
            currentUrl = this.updateQueryStringParameter(currentUrl, 'priceMin', parseInt(this.selectedPriceMin))
            currentUrl = this.updateQueryStringParameter(currentUrl, 'priceMax', parseInt(this.selectedPriceMax))

            window.location.href = currentUrl
        },
        updateQueryStringParameter(uri, key, value) {
            let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
            let separator = uri.indexOf('?') !== -1 ? "&" : "?";
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + "=" + value + '$2');
            } else {
                return uri + separator + key + "=" + value;
            }
        }
    }
}
</script>
