const taxNumberActions = {
    getTaxNumberHu({ commit, dispatch }, taxNumber) {
        return axios.get('/tax-number/hu', { params: taxNumber })
    },
    getTaxNumberEu({ commit, dispatch }, taxNumber) {
        return axios.get('/tax-number/eu', { params: taxNumber })
    },
}

export default taxNumberActions
