<template>
    <div>
        <div class="checkout-discount" v-if="!coupon">
            <h4>{{ $trans('Have a coupon?') }}
                <button id="toggleCouponCode" class="btn btn-link btn-toggle">{{ $trans('Enter your code') }}</button>
            </h4>

            <div id="couponCode" class="collapse">
                <div class="feature-box">
                    <div class="feature-box-content">
                        <p>{{ $trans('If you have a coupon code, please apply it below.') }}</p>

                        <form>
                            <porto-loading-overlay v-if="loading"></porto-loading-overlay>
                            <div class="input-group">
                                <input type="text" class="form-control form-control-sm w-auto" v-model="couponCode"
                                       :placeholder="$trans('Coupon code')"
                                       required/>
                                <div class="input-group-append">
                                    <button class="btn btn-sm mt-0" @click.prevent="applyCoupon" type="submit">
                                        {{ $trans('Apply coupon') }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-12" v-if="subtotal < minimumOrderValue">
            <div class="alert alert-warning">
                <i class="fa fa-exclamation-triangle"></i> Webáruházunkban a minimális rendelési érték {{ $priceFormatter.formatPriceText(minimumOrderValue) }}. A kosaradban lévő termékek összértéke nem éri el ezt az összeget, kérlek vásárolj még {{ $priceFormatter.formatPriceText(minimumOrderValue-subtotal) }} értékben.
            </div>
        </div>

        <Form ref="checkoutForm" @submit="submitForm" :validation-schema="formSchema" v-slot="{ errors }">
            <div class="row">
                <div class="col-lg-7">
                    <porto-loading-overlay v-if="loading"></porto-loading-overlay>
                    <ul class="checkout-steps">
                        <li>
                            <h2 class="h4 step-title">{{ $trans('Billing details') }}</h2>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{'has-error': errors.billingName}">
                                        <label>{{ $trans('Name') }}
                                            <abbr class="required" :title="$trans('Required')">*</abbr>
                                        </label>
                                        <Field name="billingName" as="input" class="form-control"
                                               v-model="formData.billingAddress.name"/>
                                        <ErrorMessage class="form-error" name="billingName"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{'has-error': errors.billingEmail}">
                                        <label>{{ $trans('Email address') }} <abbr class="required"
                                                                                   :title="$trans('Required')">*</abbr></label>
                                        <Field name="billingEmail" as="input" type="email" class="form-control"
                                               v-model="formData.billingAddress.email"/>
                                        <ErrorMessage class="form-error" name="billingEmail"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{'has-error': errors.billingPhone}">
                                        <label>{{ $trans('Phone') }} <abbr class="required" :title="$trans('Required')">*</abbr></label>
                                        <Field name="billingPhone" as="input" type="text" class="form-control"
                                               v-model="formData.billingAddress.phone"/>
                                        <ErrorMessage class="form-error" name="billingPhone"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{'has-error': errors.billingTaxNumber}">
                                        <label>{{ $trans('Tax number') }}</label>
                                        <Field ref="billingTaxNumber" name="billingTaxNumber" as="input" type="text"
                                               class="form-control"
                                               v-model="formData.billingAddress.taxNumber"
                                               @input="queryBillingTaxNumber"/>
                                        <ErrorMessage class="form-error" name="billingTaxNumber"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{'has-error': errors.billingCompanyName}">
                                        <label>{{ $trans('Company name') }}</label>
                                        <Field name="billingCompanyName" as="input" type="text" class="form-control"
                                               v-model="formData.billingAddress.companyName"/>
                                        <ErrorMessage class="form-error" name="billingCompanyName"/>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>{{ $trans('Country') }} <abbr class="required"
                                                                     :title="$trans('Required')">*</abbr></label>
                                <VueMultiselect
                                    :model-value="formData.billingAddress.country"
                                    :options="countryOptions"
                                    :searchable="true"
                                    :close-on-select="true"
                                    :allow-empty="true"
                                    label="title"
                                    @update:model-value="updateBillingCountry"
                                    :placeholder="$trans('Select one')"
                                    :select-label="$trans('Press enter to select')"
                                    :deselect-label="$trans('Press enter to remove')"
                                    :selected-label="$trans('Selected')"
                                    track-by="title"
                                >
                                    <template #noResult>
                                        {{ $trans('Oops! No elements found. Consider changing the search query.') }}
                                    </template>
                                </VueMultiselect>
                                <Field name="billingCountry" as="input" type="hidden"
                                       v-model="formData.billingAddress.country"/>
                                <ErrorMessage class="form-error" name="billingCountry"/>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{'has-error': errors.billingZip}">
                                        <label>{{ $trans('Zip') }} <abbr class="required"
                                                                         :title="$trans('Required')">*</abbr></label>
                                        <Field name="billingZip" as="input" class="form-control"
                                               v-model="formData.billingAddress.zip" @input="queryBillingZip"/>
                                        <ErrorMessage class="form-error" name="billingZip"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{'has-error': errors.billingCity}">
                                        <label>{{ $trans('City') }} <abbr class="required"
                                                                          :title="$trans('Required')">*</abbr></label>
                                        <Field name="billingCity" as="input" class="form-control"
                                               v-model="formData.billingAddress.city"/>
                                        <ErrorMessage class="form-error" name="billingCity"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{'has-error': errors.billingStreet}">
                                        <label>{{ $trans('Street') }} <abbr class="required"
                                                                            :title="$trans('Required')">*</abbr></label>
                                        <Field name="billingStreet" as="input" class="form-control"
                                               v-model="formData.billingAddress.street"/>
                                        <ErrorMessage class="form-error" name="billingStreet"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{'has-error': errors.billingHouseNumber}">
                                        <label>{{ $trans('House number') }} <abbr class="required"
                                                                                  :title="$trans('Required')">*</abbr></label>
                                        <Field name="billingHouseNumber" as="input" class="form-control"
                                               v-model="formData.billingAddress.houseNumber"/>
                                        <ErrorMessage class="form-error" name="billingHouseNumber"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{'has-error': errors.billingHouseNumberInfo}">
                                        <label>{{ $trans('House number info') }}</label>
                                        <Field name="billingHouseNumberInfo" as="input" class="form-control"
                                               v-model="formData.billingAddress.houseNumberInfo"/>
                                        <ErrorMessage class="form-error" name="billingHouseNumberInfo"/>
                                    </div>
                                </div>
                            </div>

                            <div v-if="!isLoggedIn" class="form-group mb-1">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="toggleCreateAccount"
                                           v-model="formData.createUser"/>
                                    <label class="custom-control-label"
                                           :class="{'animated-checkbox': !formData.createUser}"
                                           for="toggleCreateAccount">{{ $trans('Create an account?') }}</label>
                                </div>
                            </div>

                            <div v-if="!isLoggedIn" id="createAccount" class="collapse"
                                 :class="{show: formData.createUser}">
                                <div class="form-group">
                                    <label>{{ $trans('Create account password') }}
                                        <abbr class="required" title="required">*</abbr></label>
                                    <!-- TODO: validate if checked -->
                                    <input type="password" :placeholder="$trans('Password')" class="form-control"
                                           v-model="formData.newPassword"/>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="custom-control custom-checkbox mt-0">
                                    <input type="checkbox" class="custom-control-input" id="toggleShippingAddress"
                                           @change="setDifferentShippingAddress"/>
                                    <label class="custom-control-label" for="toggleShippingAddress">{{
                                            $trans('Ship to a different address?')
                                        }}</label>
                                </div>
                            </div>

                            <div id="shippingAddress" class="collapse">
                                <div class="shipping-info">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group" :class="{'has-error': errors.shippingName}">
                                                <label>{{ $trans('Name') }}
                                                    <abbr class="required" :title="$trans('Required')">*</abbr>
                                                </label>
                                                <Field name="shippingName" as="input" class="form-control"
                                                       v-model="formData.shippingAddress.name"/>
                                                <ErrorMessage class="form-error" name="shippingName"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group" :class="{'has-error': errors.shippingEmail}">
                                                <label>{{ $trans('Email address') }} <abbr class="required"
                                                                                           :title="$trans('Required')">*</abbr></label>
                                                <Field name="shippingEmail" as="input" type="email" class="form-control"
                                                       v-model="formData.shippingAddress.email"/>
                                                <ErrorMessage class="form-error" name="shippingEmail"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group" :class="{'has-error': errors.shippingPhone}">
                                                <label>{{ $trans('Phone') }} <abbr class="required"
                                                                                   :title="$trans('Required')">*</abbr></label>
                                                <Field name="shippingPhone" as="input" type="text" class="form-control"
                                                       v-model="formData.shippingAddress.phone"/>
                                                <ErrorMessage class="form-error" name="shippingPhone"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>{{ $trans('Country') }} <abbr class="required"
                                                                             :title="$trans('Required')">*</abbr></label>
                                        <VueMultiselect
                                            :model-value="formData.shippingAddress.country"
                                            :options="countryOptions"
                                            :searchable="true"
                                            :close-on-select="true"
                                            :allow-empty="true"
                                            label="title"
                                            @update:model-value="updateShippingCountry"
                                            :placeholder="$trans('Select one')"
                                            :select-label="$trans('Press enter to select')"
                                            :deselect-label="$trans('Press enter to remove')"
                                            :selected-label="$trans('Selected')"
                                            track-by="title"
                                        >
                                            <template #noResult>
                                                {{
                                                    $trans('Oops! No elements found. Consider changing the search query.')
                                                }}
                                            </template>
                                        </VueMultiselect>
                                        <Field name="shippingCountry" as="input" type="hidden"
                                               v-model="formData.shippingAddress.country"/>
                                        <ErrorMessage class="form-error" name="shippingCountry"/>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group" :class="{'has-error': errors.shippingZip}">
                                                <label>{{ $trans('Zip') }} <abbr class="required"
                                                                                 :title="$trans('Required')">*</abbr></label>
                                                <Field name="shippingZip" as="input" class="form-control"
                                                       v-model="formData.shippingAddress.zip"/>
                                                <ErrorMessage class="form-error" name="shippingZip"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group" :class="{'has-error': errors.shippingCity}">
                                                <label>{{ $trans('City') }} <abbr class="required"
                                                                                  :title="$trans('Required')">*</abbr></label>
                                                <Field name="shippingCity" as="input" class="form-control"
                                                       v-model="formData.shippingAddress.city"/>
                                                <ErrorMessage class="form-error" name="shippingCity"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group" :class="{'has-error': errors.shippingStreet}">
                                                <label>{{ $trans('Street') }} <abbr class="required"
                                                                                    :title="$trans('Required')">*</abbr></label>
                                                <Field name="shippingStreet" as="input" class="form-control"
                                                       v-model="formData.shippingAddress.street"/>
                                                <ErrorMessage class="form-error" name="shippingStreet"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group" :class="{'has-error': errors.shippingHouseNumber}">
                                                <label>{{ $trans('House number') }} <abbr class="required"
                                                                                          :title="$trans('Required')">*</abbr></label>
                                                <Field name="shippingHouseNumber" as="input" class="form-control"
                                                       v-model="formData.shippingAddress.houseNumber"/>
                                                <ErrorMessage class="form-error" name="shippingHouseNumber"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group"
                                                 :class="{'has-error': errors.shippingHouseNumberInfo}">
                                                <label>{{ $trans('House number info') }}</label>
                                                <Field name="shippingHouseNumberInfo" as="input" class="form-control"
                                                       v-model="formData.shippingAddress.houseNumberInfo"/>
                                                <ErrorMessage class="form-error" name="shippingHouseNumberInfo"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group" :class="{'has-error': errors.commentForUs}">
                                <label>{{ $trans('Comment for Boxfice') }}</label>
                                <Field name="commentForUs" as="textarea" class="form-control"
                                       v-model="formData.commentForUs"
                                       :placeholder="$trans('commentForUsPlaceHolder')"/>
                                <ErrorMessage class="form-error" name="commentForUs"/>
                            </div>

                            <div class="form-group" :class="{'has-error': errors.commentForCourier}">
                                <label>{{ $trans('Comment for courier') }}</label>
                                <Field name="commentForCourier" as="textarea" class="form-control"
                                       v-model="formData.commentForCourier"
                                       :placeholder="$trans('commentForCourierPlaceHolder')"/>
                                <ErrorMessage class="form-error" name="commentForCourier"/>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- End .col-lg-8 -->

                <div class="col-lg-5">
                    <porto-loading-overlay v-if="loading"></porto-loading-overlay>
                    <div class="order-summary">
                        <h3>{{ $trans('YOUR ORDER') }}</h3>

                        <table class="table table-mini-cart">
                            <thead>
                            <tr>
                                <th colspan="2">{{ $trans('Product') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, key) in items" :key="item.id">
                                <td class="product-col">
                                    <h3 class="product-title">
                                        <span class="product-qty">{{ item.quantity }}</span> × {{ item.name }}
                                    </h3>
                                </td>

                                <td class="price-col">
                                    <span>{{
                                            $priceFormatter.formatPriceText(item.price * item.quantity)
                                        }}</span>
                                </td>
                            </tr>
                            <tr style="border-bottom: 1px solid #e7e7e7;" v-if="coupon">
                                <th colspan="2">{{ $trans('Coupon') }}</th>
                            </tr>
                            <tr class="coupon" v-if="coupon">
                                <td class="text-left" colspan="2">
                                    <div class="pt-2" style="position: relative">
                                        <label>{{ coupon.couponCode }} ({{ coupon.originalValue }})</label>
                                        <div v-html="coupon.description"></div>
                                        <a href="javascript:void(0);" @click="removeCoupons()" style="top: 5px !important;"
                                           class="btn-remove icon-cancel" :title="$trans('Remove Coupon')"></a>
                                    </div>
                                </td>
                            </tr>
                            <tr style="border-bottom: 1px solid #e7e7e7;" v-if="Object.keys(promos).length > 0">
                                <th colspan="2">{{ $trans('Promotions') }}</th>
                            </tr>
                            <tr class="coupon" v-for="promo in promos">
                                <td class="text-left" colspan="2">
                                    <div class="pt-2" style="position: relative">
                                        <label>{{ promo.name }}<br>({{ promo.value }}) Ft</label>
                                        <div v-html="promo.description"></div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr class="cart-subtotal">
                                <td>
                                    <h4>{{ $trans('Subtotal') }}</h4>
                                </td>

                                <td class="price-col">
                                    <span>{{ $priceFormatter.formatPriceText(subtotal) }}</span>
                                </td>
                            </tr>
                            <tr class="order-shipping">
                                <td class="text-left" colspan="2">
                                    <h4 class="m-b-sm">{{ $trans('Shipping method') }}<span class="text-danger">*</span>&nbsp<i :title="stripedShippingConditionsHelpText" class="fa fa-info-circle" style="cursor: pointer;"></i></h4>
                                    <div v-for="shippingMethod in shippingMethods" :key="shippingMethod.id"
                                         class="form-group form-group-custom-control">
                                        <div class="custom-control custom-radio">
                                            <input type="radio" @change="selectShippingMethod(shippingMethod.id)"
                                                   class="custom-control-input" name="shippingMethod"
                                                   :checked="shippingMethod.id === selectedShippingMethodId">
                                            <label class="custom-control-label">{{ shippingMethod.name }}: {{
                                                    $priceFormatter.formatPriceText(shippingMethod.grossPrice)
                                                }} <span v-if="shippingMethod.name.includes('MPL')">(7-10 munkanap)</span><span v-else>(1-3 munkanap)</span>
                                            </label>
                                            <div v-if="shippingMethod.name.includes('MPL')" class="pt-2">
                                                <div class="alert alert-warning"><p class="mb-0"><strong>+10.000 Ft kezelési költség:</strong> A jogszabályok alapján kötelesek vagyunk megjeleníteni az MPL-t, mint futárszolgálat, viszont akkora többletmunkát jelent ez számunkra, hogy kénytelenek vagyunk kezelési költséget felszámítani.</p></div>
                                            </div>
                                        </div><!-- End .custom-checkbox -->
                                    </div><!-- End .form-group -->
                                    <small class="text-info"><span class="text-danger">*</span>&nbsp;Rendszerünk sávos szállítási díjakkal dolgozik, amelyet az aktuális bruttó kosárérték határoz meg! Bővebb információ az <i :title="stripedShippingConditionsHelpText" class="fa fa-info-circle" style="cursor: pointer;"></i> ikonra kattintva</small>
                                </td>
                            </tr>

                            <tr class="order-payment">
                                <td class="text-left" colspan="2">
                                    <h4 class="m-b-sm">{{ $trans('Payment method') }}</h4>

                                    <div v-for="paymentMethod in paymentMethods" :key="paymentMethod.id"
                                         class="form-group form-group-custom-control">
                                        <div class="custom-control custom-radio">
                                            <input :disabled="paymentMethod.alias === 'cod'" type="radio" @change="selectPaymentMethod(paymentMethod.id)"
                                                   class="custom-control-input" name="paymentMethod"
                                                   :checked="paymentMethod.id === selectedPaymentMethod.id">
                                            <label class="custom-control-label">{{ paymentMethod.name }}
                                                <span v-if="paymentMethod.grossPrice > 0">
                                                    +{{
                                                        $priceFormatter.formatPriceText(paymentMethod.grossPrice)
                                                    }}</span>
                                                <img @click="selectPaymentMethod(paymentMethod.id)"
                                                     class="payment-image" v-if="paymentMethod.image" width="120"
                                                     :src="paymentMethod.image.url" :alt="paymentMethod.name">
                                            </label>
                                            <div class="pt-2">
                                                <div :class="{'alert alert-warning': paymentMethod.alias === 'cod'}" v-html="paymentMethod.description"></div>
                                            </div>
                                        </div><!-- End .custom-checkbox -->
                                    </div><!-- End .form-group -->
                                </td>
                            </tr>

                            <tr class="order-checkboxes">
                                <td class="text-left" colspan="2">
                                    <h4 class="m-b-sm">{{ $trans('Statements') }}</h4>

                                    <div class="form-group mt-1">
                                        <div class="custom-control custom-checkbox mt-0">
                                            <Field class="custom-control-input"
                                                   id="acceptTerms"
                                                   as="input"
                                                   name="acceptTerms"
                                                   type="checkbox"
                                                   :modelValue="formData.acceptTerms"
                                                   @update:modelValue="setAcceptTerms"
                                                   :value="!formData.acceptTerms"/>
                                            <label class="custom-control-label" for="acceptTerms"
                                                   v-html='$trans("I accept the <a target=\"_blank\" href=\":termsUrl\">terms of condition</a> and <a target=\"_blank\" href=\":privacyUrl\">privacy policy</a>.", {termsUrl: "/altalanos-szerzodesi-feltetelek", privacyUrl: "/adatkezelesi-tajekoztato"})'></label>
                                            <ErrorMessage class="form-error" name="acceptTerms"/>
                                        </div>
                                    </div>

                                    <div v-if="selectedPaymentMethod.alias === 'simplepay'" class="form-group mt-1">
                                        <div class="custom-control custom-checkbox mt-0">
                                            <Field class="custom-control-input"
                                                   ref="acceptSimplePayStatement"
                                                   id="acceptSimplePayStatement"
                                                   as="input"
                                                   name="acceptSimplePayStatement"
                                                   type="checkbox"
                                                   :modelValue="formData.acceptSimplePayStatement"
                                                   @update:modelValue="setAcceptSimplePayStatement"
                                                   :value="!formData.acceptSimplePayStatement"/>
                                            <label class="custom-control-label" for="acceptSimplePayStatement"
                                                   v-html='$trans("I accept the <a target=\"_blank\" href=\":simpleStatementUrl\">SimplePay statement.</a>.", {simpleStatementUrl: "/simplepay-adattovabbitasi-nyilatkozat"})'></label>
                                            <ErrorMessage class="form-error" name="acceptSimplePayStatement"/>
                                        </div>
                                    </div>

                                    <div class="form-group mt-1" v-if="!isSubscribed">
                                        <div class="custom-control custom-checkbox mt-0">
                                            <input type="checkbox" class="custom-control-input"
                                                   id="subscribeToTheNewsletter" checked @change="setSubscribeToTheNewsletter"/>
                                            <label class="custom-control-label"
                                                   :class="{'animated-checkbox': !formData.subscribeToTheNewsletter}"
                                                   for="subscribeToTheNewsletter"
                                                   v-html="$trans('I subscribe to the newsletter.')"></label>
                                        </div>
                                    </div>

                                </td>
                            </tr>

                            <tr class="order-total">
                                <td>
                                    <h4>{{ $trans('Total') }}</h4>
                                </td>
                                <td>
                                    <b class="total-price"><span>{{
                                            $priceFormatter.formatPriceText(total)
                                        }}</span></b>
                                </td>
                            </tr>
                            </tfoot>
                        </table>

                        <button type="submit" @click="submitForm" class="btn btn-dark btn-place-order" :class="{'disabled pointer-events-none': subtotal < minimumOrderValue}">
                            {{ $trans('Go to finalization') }}
                        </button>
                    </div>
                    <!-- End .cart-summary -->
                </div>
                <!-- End .col-lg-4 -->
            </div>
        </Form>
        <!-- End .row -->
    </div>
</template>

<script>

import {ErrorMessage, Field, Form} from "vee-validate";
import {getName as getCountryName, registerLocale} from 'i18n-iso-countries'
import hu from 'i18n-iso-countries/langs/hu.json'
import VueMultiselect from 'vue-multiselect'
import PortoLoadingOverlay from "./PortoLoadingOverlay.vue";
import {formatTaxNumber} from "../formatters/taxNumberFormatter";
import {setupVeeValidate} from "../utils/veeValidate";

export default {
    props: {
        minimumOrderValue: {
            type: Number,
            required: true
        }
    },
    created() {
        setupVeeValidate();
        registerLocale(hu);
        this.loading = true
        this.$store.dispatch('fetchCountries').then(response => {
            Object.keys(response.data.entities).forEach(key => {
                let country = response.data.entities[key]
                let iso2 = country.iso2
                let name = getCountryName(iso2, hu.locale)

                this.countryOptions.push({
                    value: country.id,
                    title: name,
                    iso2: country.iso2
                })

                if (iso2 === 'hu') {
                    this.formData.billingAddress.country = {
                        value: country.id,
                        title: name,
                        iso2: country.iso2
                    }
                }
            })
        })
    },
    mounted() {
        this.initData()
    },
    components: {
        PortoLoadingOverlay,
        Form,
        Field,
        ErrorMessage,
        VueMultiselect
    },
    data() {
        return {
            couponCode: '',
            loading: false,
            isLoggedIn: false,
            isSubscribed: false,
            differentShippingAddress: false,
            dataLoaded: false,
            formData: {
                billingAddress: {
                    name: '',
                    email: '',
                    phone: '',
                    country: '',
                    zip: '',
                    city: '',
                    street: '',
                    houseNumber: '',
                    houseNumberInfo: '',
                    companyName: '',
                    taxNumber: '',
                },
                shippingAddress: {
                    name: '',
                    email: '',
                    phone: '',
                    country: '',
                    zip: '',
                    city: '',
                    street: '',
                    houseNumber: '',
                    houseNumberInfo: '',
                    companyName: '',
                    taxNumber: '',
                },
                commentForUs: '',
                commentForCourier: '',
                createUser: false,
                newPassword: '',
                acceptTerms: false,
                acceptSimplePayStatement: false,
                subscribeToTheNewsletter: true
            },
            formSchema: {
                billingName: {
                    required: true,
                },
                billingEmail: {
                    required: true,
                    email: true,
                },
                billingPhone: {
                    required: true,
                    phone: true,
                },
                billingCompanyName: {},
                billingTaxNumber: {
                    taxNumber: [this.selectedBillingCountryCode]
                },
                billingCountry: {
                    required: true,
                },
                billingZip: {
                    required: true,
                },
                billingCity: {
                    required: true,
                },
                billingStreet: {
                    required: true,
                },
                billingHouseNumber: {
                    required: true,
                },
                billingHouseNumberInfo: {},
                shippingName: {
                    required: true,
                },
                shippingEmail: {
                    required: true,
                    email: true,
                },
                shippingPhone: {
                    required: true,
                    phone: true,
                },
                shippingCompanyName: {},
                shippingTaxNumber: {
                    taxNumber: [this.selectedShippingCountryCode]
                },
                shippingCountry: {
                    required: true,
                },
                shippingZip: {
                    required: true,
                },
                shippingCity: {
                    required: true,
                },
                shippingStreet: {
                    required: true,
                },
                shippingHouseNumber: {
                    required: true,
                },
                shippingHouseNumberInfo: {},
                commentForUs: {},
                commentForCourier: {},
                acceptTerms: {
                    terms: true
                },
                acceptSimplePayStatement: (value) => {

                    if (this.selectedPaymentMethodAlias !== "simplepay") {
                        return true;
                    } else {
                        if (typeof value == "undefined") {
                            return this.$trans('youMustAcceptSimplePayStatement')
                        }
                    }

                    if (!value) {
                        return this.$trans('youMustAcceptSimplePayStatement')
                    }

                    return true
                },
                subscribeToTheNewsletter: {},
            },
            countryOptions: [],
            items: '',
            itemsCount: 0,
            subtotal: 0,
            total: 0,
            shippingMethods: '',
            selectedShippingMethodId: '',
            paymentMethods: '',
            selectedPaymentMethod: '',
            coupon: '',
            promos: [],
            stripedShippingConditionsHelpText: '',
        }
    },
    methods: {
        initData() {
            this.loading = true
            this.$store.dispatch('initCurrentUser').then(() => {
                let storedCheckoutData = this.$storageHelper.getLocalStorageItemWithExpiry('checkoutFormData')
                if (storedCheckoutData) {
                    this.formData = JSON.parse(storedCheckoutData)
                } else {
                    this.setDefaultBillingAddress()
                    this.setDefaultShippingAddress()
                }

                let user = this.$store.getters.user
                if (user) {
                    this.isSubscribed = user.isSubscribed
                    this.isLoggedIn = true
                }
                this.$nextTick(() => {
                    this.dataLoaded = true
                })
            }).catch(() => {
                let storedCheckoutData = this.$storageHelper.getLocalStorageItemWithExpiry('checkoutFormData')
                if (storedCheckoutData) {
                    this.formData = JSON.parse(storedCheckoutData)
                }
                this.dataLoaded = true
            })

            this.initCart()
        },
        initCart() {
            this.$store.dispatch('fetchCartItems').then(response => {
                this.items = response.data.entities
                this.subtotal = response.data.subtotal
                this.total = response.data.total
                this.stripedShippingConditionsHelpText = response.data.stripedShippingConditionsHelpText
                this.shippingMethods = response.data.shippingMethods
                this.selectedShippingMethodId = response.data.selectedShippingMethodId
                this.paymentMethods = response.data.paymentMethods
                this.selectedPaymentMethod = response.data.selectedPaymentMethod
                this.coupon = response.data.coupon
                this.promos = response.data.promos
                this.itemsCount = Object.keys(this.items).length
                if (this.itemsCount === 0) {
                    window.location.href = '/termekek'
                }
            }).finally(() => {

                let items = []
                Object.keys(this.items).forEach(key => {
                    items.push({
                        item_name: this.items[key].name,
                        price: this.items[key].price,
                        item_category: this.items[key].associatedModel.category.name,
                        quantity: this.items[key].quantity
                    })
                })

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'begin_checkout',
                    ecommerce: {
                        currency: 'HUF',
                        value: this.$priceFormatter.formatPriceNumber(this.total),
                        items: items
                    }
                });

                this.addShippingInfo()
                this.addPaymentInfo()

                this.loading = false
            })
        },
        addShippingInfo() {
            let items = []
            Object.keys(this.items).forEach(key => {
                items.push({
                    item_name: this.items[key].name,
                    price: this.$priceFormatter.formatPriceNumber(this.items[key].price),
                    item_category: this.items[key].associatedModel.category.name,
                    quantity: this.items[key].quantity
                })
            })

            let selectedShippingMethod
            let selectedShippingMethodPrice = 0;
            Object.keys(this.shippingMethods).forEach(key => {
                if (this.shippingMethods[key].id === this.selectedShippingMethodId) {
                    selectedShippingMethod = this.shippingMethods[key].name
                    selectedShippingMethodPrice = this.shippingMethods[key].grossPrice
                }
            })

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'add_shipping_info',
                ecommerce: {
                    currency: 'HUF',
                    value: this.$priceFormatter.formatPriceNumber(selectedShippingMethodPrice),
                    shipping_tier: selectedShippingMethod,
                    items: items
                }
            });
        },
        addPaymentInfo() {
            let items = []
            Object.keys(this.items).forEach(key => {
                items.push({
                    item_name: this.items[key].name,
                    price: this.$priceFormatter.formatPriceNumber(this.items[key].price),
                    item_category: this.items[key].associatedModel.category.name,
                    quantity: this.items[key].quantity
                })
            })

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'add_payment_info',
                ecommerce: {
                    currency: 'HUF',
                    // TODO: ha lesz utánvét
                    value: 0,
                    payment_type: this.selectedPaymentMethodAlias,
                    items: items
                }
            });
        },
        applyCoupon() {
            this.loading = true
            this.$store.dispatch('applyCoupon', {couponCode: this.couponCode}).then(() => {
                this.initData()
                let headerCart = this.$root.$refs["headerCart"];
                if(headerCart) {
                    headerCart.initData()
                }
                let mobileCartIcon = this.$root.$refs["mobileCartIcon"];
                if(mobileCartIcon) {
                    mobileCartIcon.initData()
                }
            }).catch(error => {
                if (error.response.status === 500) {
                    Porto.miniPopup.open({
                        name: 'Hiba!',
                        nameLink: '#',
                        imageSrc: '/img/error.png',
                        imageLink: '#',
                        content: error.response.data.message,
                        action: '',
                    });
                }
            }).finally(() => {
                this.couponCode = ''
                this.loading = false
            })
        },
        removeCoupons() {
            this.loading = true
            this.$store.dispatch('removeCoupons').then(() => {
                this.initData()
                let headerCart = this.$root.$refs["headerCart"];
                if(headerCart) {
                    headerCart.initData()
                }
                let mobileCartIcon = this.$root.$refs["mobileCartIcon"];
                if(mobileCartIcon) {
                    mobileCartIcon.initData()
                }
            }).catch(error => {
                if (error.response.status === 500) {
                    Porto.miniPopup.open({
                        name: 'Hiba!',
                        nameLink: '#',
                        imageSrc: '/img/error.png',
                        imageLink: '#',
                        content: error.response.data.message,
                        action: '',
                    });
                }
            }).finally(() => {
                this.couponCode = ''
                this.loading = false
            })
        },
        submitForm() {

            if (!this.differentShippingAddress) {
                this.formData.shippingAddress = {
                    name: this.formData.billingAddress.name,
                    email: this.formData.billingAddress.email,
                    phone: this.formData.billingAddress.phone,
                    country: this.formData.billingAddress.country,
                    zip: this.formData.billingAddress.zip,
                    city: this.formData.billingAddress.city,
                    street: this.formData.billingAddress.street,
                    houseNumber: this.formData.billingAddress.houseNumber,
                    houseNumberInfo: this.formData.billingAddress.houseNumberInfo,
                    companyName: this.formData.billingAddress.companyName,
                    taxNumber: this.formData.billingAddress.taxNumber,
                }
            }

            this.$refs.checkoutForm.validate().then(validated => {
                if (validated.valid) {
                    window.location.href = '/rendeles-veglegesitese'
                } else {
                    console.log(validated)
                }
            })
        },
        setDefaultBillingAddress() {
            let user = this.$store.getters.user
            if (user) {
                this.isLoggedIn = true
                let billingAddress = user.billing_address

                this.formData.billingAddress.name = billingAddress.name
                this.formData.billingAddress.email = billingAddress.email
                this.formData.billingAddress.phone = billingAddress.phone
                Object.keys(this.countryOptions).forEach(key => {
                    if (this.countryOptions[key].iso2.toUpperCase() === billingAddress.country.toUpperCase()) {
                        this.formData.billingAddress.country = this.countryOptions[key]
                    }
                })
                this.formData.billingAddress.taxNumber = billingAddress.taxNumber
                this.formData.billingAddress.companyName = billingAddress.companyName
                this.formData.billingAddress.zip = billingAddress.zip
                this.formData.billingAddress.city = billingAddress.city
                this.formData.billingAddress.street = billingAddress.street
                this.formData.billingAddress.houseNumber = billingAddress.houseNumber
                this.formData.billingAddress.houseNumberInfo = billingAddress.houseNumberInfo
            }
        },
        setDefaultShippingAddress() {
            /**
             * Alapértelmezetten a számlázási és szállítási cím megegyezik,
             * ezért a shippingAddressnek beállítjuk a billingAddress-t
             * csak, hogy ne nézz hülyének @Kristóf, nem basztam el :D
             */
            let user = this.$store.getters.user
            if (user) {
                this.isLoggedIn = true
                let billingAddress = user.billing_address

                this.formData.shippingAddress.name = billingAddress.name
                this.formData.shippingAddress.email = billingAddress.email
                this.formData.shippingAddress.phone = billingAddress.phone
                Object.keys(this.countryOptions).forEach(key => {
                    if (this.countryOptions[key].iso2.toUpperCase() === billingAddress.country.toUpperCase()) {
                        this.formData.shippingAddress.country = this.countryOptions[key]
                    }
                })
                this.formData.shippingAddress.zip = billingAddress.zip
                this.formData.shippingAddress.city = billingAddress.city
                this.formData.shippingAddress.street = billingAddress.street
                this.formData.shippingAddress.houseNumber = billingAddress.houseNumber
                this.formData.shippingAddress.houseNumberInfo = billingAddress.houseNumberInfo
            }
        },
        setDifferentShippingAddress() {
            this.differentShippingAddress = !this.differentShippingAddress
            let user = this.$store.getters.user
            let newAddress

            if (user && user.shipping_address && user.billing_address) {
                if (this.differentShippingAddress) {
                    newAddress = user.shipping_address
                } else {
                    newAddress = user.billing_address
                }

                this.formData.shippingAddress.name = newAddress.name
                this.formData.shippingAddress.email = newAddress.email
                this.formData.shippingAddress.phone = newAddress.phone
                Object.keys(this.countryOptions).forEach(key => {
                    if (this.countryOptions[key].iso2.toUpperCase() === newAddress.country.toUpperCase()) {
                        this.formData.shippingAddress.country = this.countryOptions[key]
                    }
                })
                this.formData.shippingAddress.zip = newAddress.zip
                this.formData.shippingAddress.city = newAddress.city
                this.formData.shippingAddress.street = newAddress.street
                this.formData.shippingAddress.houseNumber = newAddress.houseNumber
                this.formData.shippingAddress.houseNumberInfo = newAddress.houseNumberInfo
            }
        },

        updateBillingCountry(selectedOption) {
            this.formData.billingAddress.country = selectedOption
        },
        updateShippingCountry(selectedOption) {
            this.formData.shippingAddress.country = selectedOption
        },
        queryBillingTaxNumber() {
            let countryCode = this.selectedBillingCountryCode

            let taxNumber = formatTaxNumber(this.formData.billingAddress.taxNumber, countryCode.toUpperCase())
            this.formData.billingAddress.taxNumber = taxNumber

            this.$refs.billingTaxNumber.validate().then(validated => {
                if (validated.valid) {
                    this.loading = true
                    let actionName
                    let isoCode

                    // Ha Magyarország
                    if (countryCode === 'hu') {
                        actionName = 'getTaxNumberHu'
                        isoCode = ''
                    } else {
                        actionName = 'getTaxNumberEu';
                        isoCode = countryCode.toUpperCase()
                    }

                    this.$store.dispatch(actionName, {'taxNumber': isoCode + taxNumber}).then(response => {
                        let taxpayer = response.data.taxpayer

                        this.formData.billingAddress.companyName = taxpayer.name
                        this.formData.billingAddress.taxNumber = taxpayer.taxNumber
                        this.formData.billingAddress.zip = taxpayer.zip
                        this.formData.billingAddress.city = taxpayer.city
                        this.formData.billingAddress.street = taxpayer.street
                        this.formData.billingAddress.houseNumber = taxpayer.houseNumber
                        this.formData.billingAddress.houseNumberInfo = taxpayer.houseNumberInfo
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            })
        },
        queryBillingZip() {

        },
        selectShippingMethod(id) {
            this.loading = true
            this.$store.dispatch('selectShippingMethod', {id: id}).then(() => {
                let headerCart = this.$root.$refs["headerCart"];
                if(headerCart) {
                    headerCart.initData()
                }
                let mobileCartIcon = this.$root.$refs["mobileCartIcon"];
                if(mobileCartIcon) {
                    mobileCartIcon.initData()
                }
                this.initCart()
            }).finally(() => {
                this.addShippingInfo()

                this.loading = false
            })
        },
        selectPaymentMethod(id) {
            this.loading = true
            this.$store.dispatch('selectPaymentMethod', {id: id}).then(() => {
                let headerCart = this.$root.$refs["headerCart"];
                if(headerCart) {
                    headerCart.initData()
                }
                let mobileCartIcon = this.$root.$refs["mobileCartIcon"];
                if(mobileCartIcon) {
                    mobileCartIcon.initData()
                }
                this.initCart()
            }).finally(() => {
                if (this.$refs.acceptSimplePayStatement) {
                    this.$refs.acceptSimplePayStatement.validate()
                }

                this.addPaymentInfo()

                this.loading = false
            })
        },

        setAcceptTerms() {
            this.formData.acceptTerms = !this.formData.acceptTerms
        },
        setAcceptSimplePayStatement() {
            this.formData.acceptSimplePayStatement = !this.formData.acceptSimplePayStatement
        },
        setSubscribeToTheNewsletter() {
            this.formData.subscribeToTheNewsletter = !this.formData.subscribeToTheNewsletter
        },
    },
    watch: {
        formData: {
            handler(formData) {
                if (this.dataLoaded) {
                    this.$store.commit('SET_CHECKOUT_FORM_DATA', formData);
                }
            },
            deep: true,
        },
    },
    computed: {
        selectedBillingCountryCode() {
            return this.formData.billingAddress.country.iso2
        },
        selectedShippingCountryCode() {
            return this.formData.shippingAddress.country.iso2
        },
        selectedPaymentMethodAlias() {
            return this.selectedPaymentMethod.alias
        },
    },
}
</script>

<style>
.multiselect, .multiselect__input, .multiselect__single, .multiselect__placeholder {font-size: 1.2rem !important;}
.multiselect__input, .multiselect__single {font-weight: 400;line-height: 1.5;color: #555;}

.multiselect__tags {min-height: 49px !important; border-radius: 0 !important; border-color: #dfdfdf !important;padding-top: 14px !important;}
.multiselect__select {height: 45px !important;}
.multiselect__placeholder {padding-left: 5px;}
</style>
