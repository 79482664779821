const cartActions = {
    fetchCartItems() {
        return window.axios.get('/cart');
    },
    addToCart({commit, dispatch}, data) {
        return window.axios.post('/cart/add', {id: data.id, quantity: data.quantity, type: data.type})
    },
    updateCart({commit, dispatch}, data) {
        let items = ''
        let item = ''
        if(data.items) {
            items = data.items
        }
        if(data.item) {
            item = data.item
        }

        return window.axios.post('/cart/update', {items: items, item: item})
    },
    removeFromCart({commit, dispatch}, data) {
        return window.axios.post('/cart/remove', {id: data.id, type: data.type})
    },
    emptyCart({}) {
        return window.axios.post('/cart/empty')
    },
    applyCoupon({commit, dispatch}, data) {
        return window.axios.post('/cart/coupon/apply', {couponCode: data.couponCode})
    },
    removeCoupons() {
        return window.axios.post('/cart/coupons/remove')
    },
    selectShippingMethod({commit, dispatch}, data) {
        return window.axios.post('/cart/shippingMethod/select', {id: data.id})
    },
    selectPaymentMethod({commit, dispatch}, data) {
        return window.axios.post('/cart/paymentMethod/select', {id: data.id})
    },
}

export default cartActions
