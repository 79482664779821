import moment from "moment"

const dateHelper = {
    formatLaravelTimestampToDate(datetime) {
        const mDate = moment(datetime)
        return mDate.isValid() ? mDate.format('YYYY. MM. DD.') : '-'
    },
}

export default dateHelper
