const wishlistActions = {
    fetchWishlistItems() {
        return window.axios.get('/wishlist');
    },
    addToWishlist({commit, dispatch}, data) {
        return window.axios.post('/wishlist/add', {id: data.id})
    },
    removeFromWishlist({commit, dispatch}, data) {
        return window.axios.post('/wishlist/remove', {id: data.id})
    },
}

export default wishlistActions
