<template>
    <main class="main main-successfulOrder">
        <div class="container checkout-container">
            <porto-loading-overlay v-if="loading"></porto-loading-overlay>
            <div class="row">
                <div class="col-lg-8 mx-auto px-0">
                    <div
                        class="line-height-md text-center text-v-dark"
                        :class="{
                        'thankyou-order-recieved': paymentDetails.Status === 'Reserved' || paymentDetails.Status === 'Authorized' || paymentDetails.Status === 'Succeeded' || paymentDetails.Status === 'PartiallySucceeded',
                        'thankyou-order-failed': paymentDetails.Status === 'Canceled' || paymentDetails.Status === 'Failed' || paymentDetails.Status === 'Expired'
                    }"
                    >
                        <i class="fas fa-check me-2"></i>
                        {{ $trans('barion.title.'+paymentDetails.Status) }}
                        <br>
                        {{ $trans('barion.description.'+paymentDetails.Status) }}
                    </div>

                    <div class="order-info">
                        <div class="order order-item">
                            {{ $trans('Order Number') }}
                            <mark class="font-weight-bold order-number">{{ idString }}</mark>
                        </div>

                        <div class="status order-item">
                            {{ $trans('Status') }}
                            <mark class="font-weight-bold order-status text-turq">{{ $trans('orders.statuses.'+order.status) }}</mark>
                        </div>

                        <div class="date order-item">
                            {{ $trans('Date') }}
                            <mark class="font-weight-bold order-date">{{ $dateHelper.formatLaravelTimestampToDate(order.createdAt) }}</mark>
                        </div>

                        <div class="total order-item">
                            {{ $trans('Total') }}
                            <mark class="font-weight-bold order-total">
                                <span class="amount">
                                    {{ $priceFormatter.formatPriceText(order.grossTotal) }}
                                </span>
                            </mark>
                        </div>

                        <div class="method order-item">
                            {{ $trans('Payment method') }}
                            <mark class="font-weight-bold order-status">{{ order.payment_method.name }}</mark>
                        </div>
                    </div>

                    <div class="order-details mb-3" v-if="order.status === 'payment'">
                        <div class="featured-boxes">
                            <div class="row">
                                <div class="col-12">
                                    <h4 class="card-sub-title text-md">{{ $trans('Pay now with card') }}</h4>
                                    <p><small>A fizetési folyamat elindításához kattints a kívánt szolgáltatóra</small></p>
                                    <a :href="'/payment/barion/start-payment/'+order.id" class="btn btn-barion">
                                        <img style="max-height: 20px; cursor: pointer" src="/storage/barion-logo.svg" alt="barion" title="Barion" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End .container -->
    </main>
    <!-- End .main -->
</template>

<script>

import stringHelper from "../helpers/stringHelper";

export default {
    props: {
        orderString: {
            type: String,
            required: true
        },
        paymentDetailsString: {
            type: String,
            required: true
        }
    },
    created() {
        this.order = JSON.parse(this.orderString)
        this.paymentDetails = JSON.parse(this.paymentDetailsString)
        this.idString = '#'+stringHelper.pad("0")(8)(this.order.id)
    },
    data(){
        return {
            idString: '',
            loading: false,
            order: '',
            paymentDetails: '',
        }
    },
}
</script>
