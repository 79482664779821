<template>
    <main class="main">
        <div class="container">
            <ul class="checkout-progress-bar d-flex justify-content-center flex-wrap">
                <li class="active">
                    <a href="/kosar">{{ $trans('Cart') }}</a>
                </li>
                <li :class="{'disabled': subtotal < minimumOrderValue}">
                    <a href="/penztar">{{ $trans('Checkout') }}</a>
                </li>
                <li class="disabled">
                    <a href="/rendeles-veglegesitese">{{ $trans('Order complete') }}</a>
                </li>
            </ul>
            <div class="row">
                <div class="col-lg-12" v-if="subtotal < minimumOrderValue">
                    <div class="alert alert-warning">
                        <i class="fa fa-exclamation-triangle"></i> Webáruházunkban a minimális rendelési érték
                        {{ $priceFormatter.formatPriceText(minimumOrderValue) }}. A kosaradban lévő termékek összértéke
                        nem éri el ezt az összeget, kérlek vásárolj még
                        {{ $priceFormatter.formatPriceText(minimumOrderValue - subtotal) }} értékben.
                    </div>
                </div>
                <div class="col-lg-12" v-if="!Object.keys(items).length">
                    <p class="text-center"><strong>{{ $trans('You have no items in your shopping cart.') }}</strong></p>
                </div>
                <div class="col-lg-8">
                    <porto-loading-overlay v-if="loading"></porto-loading-overlay>
                    <div class="cart-table-container">
                        <table class="table table-cart">
                            <thead v-if="Object.keys(items).length">
                            <tr>
                                <th class="thumbnail-col"></th>
                                <th class="product-col">{{ $trans('Product') }}</th>
                                <th class="price-col">{{ $trans('Price') }}</th>
                                <th class="qty-col">{{ $trans('Quantity') }}</th>
                                <th class="text-right">{{ $trans('Subtotal') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="product-row" v-for="(item, key) in items" :key="item.id">
                                <td>
                                    <figure class="product-image-container">
                                        <a v-if="item.associatedModel.modelType === 'App\\Models\\Product'"
                                           :href="'/termek/'+item.associatedModel.slug+'-p'+item.associatedModel.id"
                                           class="product-image">
                                            <img :src="item.associatedModel.mainImage" alt="product">
                                        </a>
                                        <a v-else
                                           :href="'/termekcsomag/'+item.associatedModel.slug+'-p'+item.associatedModel.id"
                                           class="product-image">
                                            <img :src="item.associatedModel.mainImage" alt="product">
                                        </a>

                                        <a href="javascript:void(0);" @click="removeFromCart(item.associatedModel)"
                                           class="btn-remove icon-cancel" :title="$trans('Remove Product')"></a>
                                    </figure>
                                </td>
                                <td class="product-col">
                                    <h5 class="product-title">
                                        <a v-if="item.associatedModel.modelType === 'App\\Models\\Product'"
                                           :href="'/termek/'+item.associatedModel.slug+'-p'+item.associatedModel.id">{{
                                                item.name
                                            }}</a>
                                        <a v-else
                                           :href="'/termekcsomag/'+item.associatedModel.slug+'-p'+item.associatedModel.id">{{
                                                item.name
                                            }}</a>
                                        <small>{{ $trans(item.associatedModel.stockStatus) }}</small>
                                        <div v-if="parseInt(item.associatedModel.stock) < parseInt(item.quantity)"
                                             :class="{'low-stock': parseInt(item.associatedModel.stock) < parseInt(item.quantity)}"><small>Figyelem! Csak {{
                                                parseInt(item.associatedModel.stock)
                                            }} {{ item.associatedModel.unit.name }} van raktáron.</small></div>
                                        <div v-if="coupon && item.coupon">
                                            {{ item.coupon.value }} ({{ item.coupon.attributes.couponCode }})
                                        </div>
                                        <div v-if="item.promo">
                                            Eredeti ár: {{ $priceFormatter.formatPriceText(item.promo.attributes.originalValue) }}
                                        </div>
                                        <div class="alert alert-warning" v-if="coupon && !item.coupon" style="padding: 5px 15px; margin-bottom: 0; margin-top: 10px;">
                                            <small>{{ $trans('The coupon cannot be combined with other discounts.') }}</small>
                                        </div>
                                    </h5>
                                </td>
                                <td>{{ $priceFormatter.formatPriceText(item.price) }}</td>
                                <td>
                                    <div class="product-single-qty">
                                        <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                            <span class="input-group-btn input-group-prepend">
                                                <button @click="removeOne(key)"
                                                        class="btn btn-outline btn-down-icon bootstrap-touchspin-down"
                                                        type="button"></button>
                                            </span>
                                            <input class="horizontal-quantity form-control" v-model="item.quantity"
                                                   type="number" @change="validateQuantity(key)">
                                            <span class="input-group-btn input-group-append">
                                                <button @click="addOne(key)"
                                                        class="btn btn-outline btn-up-icon bootstrap-touchspin-up"
                                                        type="button"></button>
                                            </span>
                                        </div>
                                    </div><!-- End .product-single-qty -->
                                </td>
                                <td class="text-right"><span class="subtotal-price">{{
                                        $priceFormatter.formatPriceText(item.price * item.quantity)
                                    }}</span></td>
                            </tr>
                            </tbody>

                            <tfoot v-if="Object.keys(items).length">
                            <tr>
                                <td colspan="5" class="clearfix">
                                    <div class="float-left">
                                        <div class="cart-discount" v-if="!coupon">
                                            <form>
                                                <div class="input-group">
                                                    <input type="text" v-model="couponCode"
                                                           class="form-control form-control-sm"
                                                           :placeholder="$trans('Coupon code')" required>
                                                    <div class="input-group-append">
                                                        <button @click.prevent="applyCoupon" class="btn btn-sm"
                                                                type="submit">{{ $trans('Apply coupon') }}
                                                        </button>
                                                    </div>
                                                </div><!-- End .input-group -->
                                            </form>
                                        </div>
                                    </div><!-- End .float-left -->

                                    <div class="float-right">
                                        <button @click.prevent="emptyCart" type="submit"
                                                class="btn btn-shop btn-update-cart">
                                            {{ $trans('Empty cart') }}
                                        </button>
                                    </div><!-- End .float-right -->
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                    </div><!-- End .cart-table-container -->
                </div><!-- End .col-lg-8 -->

                <div class="col-lg-4">
                    <porto-loading-overlay v-if="loading"></porto-loading-overlay>
                    <div class="cart-summary" v-if="Object.keys(items).length">
                        <h3>{{ $trans('CART TOTALS') }}</h3>

                        <table class="table table-totals">
                            <tbody v-if="coupon || Object.keys(promos).length > 0">
                            <tr style="border-bottom: 1px solid #e7e7e7;" v-if="coupon">
                                <th colspan="2" style="text-align: left;font-weight: bold;color: black;">
                                    {{ $trans('Coupon') }}
                                </th>
                            </tr>
                            <tr class="coupon" v-if="coupon">
                                <td class="text-left" colspan="2">
                                    <div class="pt-2" style="position: relative">
                                        <label>{{ coupon.couponCode }} ({{ coupon.originalValue }})</label>
                                        <div v-html="coupon.description"></div>
                                        <a href="javascript:void(0);" @click="removeCoupons()"
                                           style="top: 5px !important;"
                                           class="btn-remove icon-cancel" :title="$trans('Remove Coupon')"></a>
                                    </div>
                                </td>
                            </tr>
                            <tr style="border-bottom: 1px solid #e7e7e7;" v-if="Object.keys(promos).length > 0">
                                <th colspan="2" style="text-align: left;font-weight: bold;color: black;">
                                    {{ $trans('Promotions') }}
                                </th>
                            </tr>
                            <tr class="coupon" v-for="promo in promos">
                                <td class="text-left" colspan="2">
                                    <div class="pt-2" style="position: relative">
                                        <label>{{ promo.name }}<br>({{ promo.value }} Ft)</label>
                                        <div v-html="promo.description"></div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <td>{{ $trans('Total') }}</td>
                                <!-- Ez itt nem hibás működés, kosárban még nem írjuk ki a fizetési mód + extra díjak összegét -->
                                <td>{{ $priceFormatter.formatPriceText(subtotal) }}</td>
                            </tr>
                            </tfoot>
                        </table>

                        <div class="checkout-methods">
                            <a href="/penztar" class="btn btn-block btn-dark"
                               :class="{'disabled pointer-events-none': subtotal < minimumOrderValue}">{{
                                    $trans('Go to checkout')
                                }}
                                <i class="fa fa-arrow-right"></i></a>
                        </div>
                    </div><!-- End .cart-summary -->
                </div><!-- End .col-lg-4 -->
            </div><!-- End .row -->
        </div><!-- End .container -->

        <div class="mb-6"></div><!-- margin -->
    </main><!-- End .main -->
</template>

<script>

import portoLoadingOverlay from "./PortoLoadingOverlay.vue";

export default {
    created() {
        this.initData()
    },
    components: {
        portoLoadingOverlay
    },
    props: {
        minimumOrderValue: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            items: '',
            itemsCount: 0,
            netSubtotal: 0,
            subtotal: 0,
            total: 0,
            couponCode: '',
            shippingMethods: '',
            selectedShippingMethodId: '',
            coupon: '',
            loading: false,
            promos: []
        }
    },
    methods: {
        initData() {
            this.loading = true
            this.$store.dispatch('fetchCartItems').then(response => {
                this.items = response.data.entities
                this.subtotal = response.data.subtotal
                this.netSubtotal = response.data.netSubtotal
                this.total = response.data.total
                this.shippingMethods = response.data.shippingMethods
                this.selectedShippingMethodId = response.data.selectedShippingMethodId
                this.coupon = response.data.coupon
                this.promos = response.data.promos
                this.itemsCount = Object.keys(this.items).length

                let items = []
                Object.keys(this.items).forEach(key => {
                    items.push({
                        item_name: this.items[key].name,
                        price: this.items[key].price,
                        item_category: this.items[key].associatedModel.category.name,
                        quantity: this.items[key].quantity
                    })
                })

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'view_cart',
                    ecommerce: {
                        currency: 'HUF',
                        items: items,
                        value: this.$priceFormatter.formatPriceNumber(this.total)
                    }
                });

            }).finally(() => {
                this.loading = false
            })
        },
        addOne(key) {
            if (this.items[key].associatedModel.minimumOrderMultiply) {
                this.items[key].quantity += parseInt(this.items[key].associatedModel.minimumOrderQuantity)
            } else {
                this.items[key].quantity++
            }
            this.updateCart()
        },
        removeOne(key) {
            if (this.items[key].quantity === 0) {
                return false;
            }

            if (this.items[key].associatedModel.minimumOrderMultiply) {
                this.items[key].quantity -= parseInt(this.items[key].associatedModel.minimumOrderQuantity)
            } else {
                this.items[key].quantity--
            }

            this.updateCart()
        },
        removeFromCart(product) {

            let quantity = 1
            Object.keys(this.items).forEach(key => {
                if (this.items[key].id === product.id) {
                    quantity = this.items[key].quantity
                }
            })

            this.loading = true
            this.$store.dispatch('removeFromCart', {id: product.id, type: product.modelType}).then(() => {
                this.initData()
                let headerCart = this.$root.$refs["headerCart"];
                if (headerCart) {
                    headerCart.initData()
                }
                let mobileCartIcon = this.$root.$refs["mobileCartIcon"];
                if (mobileCartIcon) {
                    mobileCartIcon.initData()
                }
                Porto.miniPopup.open({
                    name: product.name,
                    nameLink: '/termek' + product.slug + '/-p' + product.id,
                    imageSrc: product.mainImage,
                    imageLink: '/termek' + product.slug + '/-p' + product.id,
                    content: this.$trans('has been removed from your cart.'),
                    action: '<a href="/kosar" class="btn viewcart">' + this.$trans('View cart') + '</a>',
                });

                let price = product.price
                if (product.salePrice > 0) {
                    price = product.salePrice
                }

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'remove_from_cart',
                    ecommerce: {
                        // TODO: ha lesz több valuta, tegyük dinamikussá
                        currency: 'HUF',
                        value: this.$priceFormatter.formatPriceNumber(quantity * price * (1 + (product.taxRate / 100))),
                        items: [
                            {
                                item_name: product.name,
                                price: this.$priceFormatter.formatPriceNumber(price * (1 + (product.taxRate / 100))),
                                item_category: product.category.name,
                                quantity: quantity
                            },
                        ]
                    }
                })

            }).catch(error => {
                if (error.response.status === 500) {
                    Porto.miniPopup.open({
                        name: 'Hiba!',
                        nameLink: '#',
                        imageSrc: '/img/error.png',
                        imageLink: '#',
                        content: error.response.data.message,
                        action: '',
                    });
                }
            }).finally(() => {
                this.loading = false
            })
        },
        applyCoupon() {
            this.loading = true
            this.$store.dispatch('applyCoupon', {couponCode: this.couponCode}).then(() => {
                this.initData()
                let headerCart = this.$root.$refs["headerCart"];
                if (headerCart) {
                    headerCart.initData()
                }
                let mobileCartIcon = this.$root.$refs["mobileCartIcon"];
                if (mobileCartIcon) {
                    mobileCartIcon.initData()
                }
            }).catch(error => {
                if (error.response.status === 500) {
                    Porto.miniPopup.open({
                        name: 'Hiba!',
                        nameLink: '#',
                        imageSrc: '/img/error.png',
                        imageLink: '#',
                        content: error.response.data.message,
                        action: '',
                    });
                }
            }).finally(() => {
                this.couponCode = ''
                this.loading = false
            })
        },
        removeCoupons() {
            this.loading = true
            this.$store.dispatch('removeCoupons').then(() => {
                this.initData()
                let headerCart = this.$root.$refs["headerCart"];
                if (headerCart) {
                    headerCart.initData()
                }
                let mobileCartIcon = this.$root.$refs["mobileCartIcon"];
                if (mobileCartIcon) {
                    mobileCartIcon.initData()
                }
            }).catch(error => {
                if (error.response.status === 500) {
                    Porto.miniPopup.open({
                        name: 'Hiba!',
                        nameLink: '#',
                        imageSrc: '/img/error.png',
                        imageLink: '#',
                        content: error.response.data.message,
                        action: '',
                    });
                }
            }).finally(() => {
                this.couponCode = ''
                this.loading = false
            })
        },
        emptyCart() {
            this.loading = true
            this.$store.dispatch('emptyCart', {items: this.items}).then(() => {
                let headerCart = this.$root.$refs["headerCart"];
                if (headerCart) {
                    headerCart.initData()
                }
                let mobileCartIcon = this.$root.$refs["mobileCartIcon"];
                if (mobileCartIcon) {
                    mobileCartIcon.initData()
                }
                this.initData()
                Porto.miniPopup.open({
                    name: this.$trans('Your cart has been emptied.'),
                    nameLink: '#',
                    imageSrc: '/img/success.png',
                    imageLink: '#',
                    content: '',
                    action: '',
                });
            }).finally(() => {
                this.loading = false
            })
        },
        updateCart() {
            this.loading = true
            this.$store.dispatch('updateCart', {items: this.items}).then(() => {
                let headerCart = this.$root.$refs["headerCart"];
                if (headerCart) {
                    headerCart.initData()
                }
                let mobileCartIcon = this.$root.$refs["mobileCartIcon"];
                if (mobileCartIcon) {
                    mobileCartIcon.initData()
                }
                this.initData()
                Porto.miniPopup.open({
                    name: this.$trans('Your cart has been updated.'),
                    nameLink: '#',
                    imageSrc: '/img/success.png',
                    imageLink: '#',
                    content: '',
                    action: '<a href="/kosar" class="btn viewcart">' + this.$trans('View cart') + '</a>',
                });
            }).catch(err => {
                Porto.miniPopup.open({
                    name: 'Hiba!',
                    nameLink: '#',
                    imageSrc: '/img/error.png',
                    imageLink: '#',
                    content: err.response.data.message,
                    action: '',
                });
                this.initData()
            }).finally(() => {
                this.loading = false
            })
        },
        selectShippingMethod(id) {
            this.loading = true
            this.$store.dispatch('selectShippingMethod', {id: id}).then(() => {
                let headerCart = this.$root.$refs["headerCart"];
                if (headerCart) {
                    headerCart.initData()
                }
                let mobileCartIcon = this.$root.$refs["mobileCartIcon"];
                if (mobileCartIcon) {
                    mobileCartIcon.initData()
                }
                this.initData()
            }).finally(() => {
                this.loading = false
            })
        },
        validateQuantity(key) {
            let item = this.items[key]

            if (parseInt(item.quantity) > 0) {
                if (item.associatedModel.minimumOrderMultiply && parseInt(item.associatedModel.minimumOrderQuantity) > 1) {
                    if (parseInt(item.quantity) % parseInt(item.associatedModel.minimumOrderQuantity) !== 0) {
                        Porto.miniPopup.open({
                            name: 'Hiba!',
                            nameLink: '#',
                            imageSrc: '/img/error.png',
                            imageLink: '#',
                            content: 'A mennyiség csak a(z) ' + parseInt(item.associatedModel.minimumOrderQuantity) + ' többszöröse lehet.',
                            action: '',
                        });

                        this.items[key].quantity = this.roundNearest(item.quantity, item.associatedModel.minimumOrderQuantity)

                        return false
                    }
                }
            }

            this.updateCart()

            return true
        },
        roundNearest(number, qty) {
            let rounded = Math.round(number / qty) * qty;
            if (rounded < qty) {
                rounded = parseInt(qty)
            }

            return rounded
        }
    }
}
</script>

<style>
.low-stock {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
}

.low-stock small {
    font-weight: bold;
}
</style>
