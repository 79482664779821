export const formatTaxNumber = (value, countryCode) => {

    let string = value;

    if(countryCode === 'HU'){
        const pattern = /[0-9]+/g

        const matched = value.match(pattern)

        if (matched && matched.length > 0) {
            const matchedString = matched.join('')

            if (matchedString.length < 9) {
                return matchedString
            }

            const pieces = []
            const clearedString = String(matched.join('').substr(0, 13))

            const piece1 = clearedString.substr(0, 8)
            if (piece1.length) {
                pieces.push(piece1)
            }

            const piece2 = clearedString.substr(8, 1)

            if (piece2.length) {
                pieces.push(piece2)
            }

            const piece3 = clearedString.substr(9, 2)

            if (piece3.length) {
                pieces.push(piece3)
            }

            return pieces.join('-')
        }

        return ''
    }

    return string;
}
