<template>
    <div class="container">
        <div v-if="Object.keys(items).length > 0" class="wishlist-title">
            <h2 class="h4 p-2">{{ $trans('My wishlist on Boxfice') }}</h2>
        </div>
        <div v-if="Object.keys(items).length > 0" class="wishlist-table-container">
            <porto-loading-overlay v-if="loading"></porto-loading-overlay>
            <table class="table table-wishlist mb-0">
                <thead>
                <tr>
                    <th class="thumbnail-col"></th>
                    <th class="product-col">{{ $trans('Product') }}</th>
                    <th class="price-col">{{ $trans('Price') }}</th>
                    <th class="status-col">{{ $trans('Stock Status') }}</th>
                    <th class="action-col">{{ $trans('Actions') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr class="product-row" v-for="(item, key) in items" :key="item.id">
                    <td>
                        <figure class="product-image-container">
                            <a :href="'/termek/'+item.associatedModel.slug+'-p'+item.associatedModel.id"
                               class="product-image">
                                <img :src="item.associatedModel.mainImage" alt="product">
                            </a>

                            <a href="javascript:void(0);" @click="removeFromWishlist(item.associatedModel)"
                               class="btn-remove icon-cancel" :title="$trans('Remove Product')"></a>
                        </figure>
                    </td>
                    <td>
                        <h5 class="product-title">
                            <a :href="'/termek/'+item.associatedModel.slug+'-p'+item.associatedModel.id">{{
                                    item.name
                                }}</a>
                        </h5>
                    </td>
                    <td class="price-box">{{ $priceFormatter.formatPriceText(item.price) }}</td>
                    <td>
                        <span class="stock-status">{{ $trans(item.associatedModel.stockStatus) }}</span>
                    </td>
                    <td class="action">
                        <a :href="'/termek/'+item.associatedModel.slug+'-p'+item.associatedModel.id" class="btn btn-quickview mt-1 mt-md-0"
                           :title="$trans('Show')">{{ $trans('Show') }}</a>
                        <add-to-cart :model="item.associatedModel" type="product" variant="wishlist"></add-to-cart>
                    </td>
                </tr>
                </tbody>
            </table>
        </div><!-- End .cart-table-container -->
        <div v-else class="wishlist-title">
            <h2 class="h4 p-2">{{ $trans('Your wishlist is empty.') }}</h2>
        </div>
    </div><!-- End .container -->
</template>

<script>
import portoLoadingOverlay from "./PortoLoadingOverlay.vue";
import AddToCart from "./AddToCart.vue";

export default {
    created() {
        this.initData()
    },
    components: {
        portoLoadingOverlay,
        AddToCart
    },
    data() {
        return {
            items: '',
            itemsCount: 0,
            loading: false,
        }
    },
    methods: {
        initData() {
            this.loading = true
            this.$store.dispatch('fetchWishlistItems').then(response => {
                this.items = response.data.entities
                this.itemsCount = Object.keys(this.items).length
            }).finally(() => {
                this.loading = false
            })
        },
        removeFromWishlist(product) {
            this.loading = true
            this.$store.dispatch('removeFromWishlist', {id: product.id}).then(() => {
                this.initData()
                Porto.miniPopup.open({
                    name: product.name,
                    nameLink: '/termek' + product.slug + '/-p' + product.id,
                    imageSrc: product.mainImage,
                    imageLink: '/termek' + product.slug + '/-p' + product.id,
                    content: this.$trans('has been removed from your wishlist.'),
                    action: '<a href="/kivansaglista" class="btn viewcart">' + this.$trans('View wishlist') + '</a>',
                });

                let price = product.price
                if(product.salePrice > 0){
                    price = product.salePrice
                }

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'remove_from_wishlist',
                    ecommerce: {
                        // TODO: ha lesz több valuta, tegyük dinamikussá
                        currency: 'HUF',
                        value: this.$priceFormatter.formatPriceNumber(price*(1+(product.taxRate/100))),
                        items: [
                            {
                                item_name: product.name,
                                price: price*(1+(product.taxRate/100)),
                                item_category: product.category.name,
                                quantity: 1
                            },
                        ]
                    }
                })
            }).catch(error => {
                if (error.response.status === 500) {
                    Porto.miniPopup.open({
                        name: 'Hiba!',
                        nameLink: '#',
                        imageSrc: '/img/error.png',
                        imageLink: '#',
                        content: error.response.data.message,
                        action: '',
                    });
                }
            }).finally(() => {
                this.loading = false
            })
        },
    }
}
</script>
