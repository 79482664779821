<template>
    <div>
        <Form ref="orderFinalizationForm" @submit="submitForm" :validation-schema="formSchema" v-slot="{ errors }">
            <div class="row">

                <div class="col-lg-12" v-if="subtotal < minimumOrderValue">
                    <div class="alert alert-warning">
                        <i class="fa fa-exclamation-triangle"></i> Webáruházunkban a minimális rendelési érték
                        {{ $priceFormatter.formatPriceText(minimumOrderValue) }}. A kosaradban lévő termékek összértéke
                        nem éri el ezt az összeget, kérlek vásárolj még
                        {{ $priceFormatter.formatPriceText(minimumOrderValue - subtotal) }} értékben.
                    </div>
                </div>

                <div class="col-lg-6">
                    <porto-loading-overlay v-if="loading"></porto-loading-overlay>
                    <div class="order-summary">
                        <h3>{{ $trans('Billing Address') }}
                            <span class="float-right">
                            <a href="/penztar" class="btn btn-dark btn-sm">
                                <i class="fas fa-pencil-alt"></i>
                            </a>
                        </span>
                        </h3>

                        <table class="table table-mini-cart">
                            <tbody>
                            <tr>
                                <td class="product-col">
                                    <h3 class="product-title">
                                        {{ $trans('Name') }}
                                    </h3>
                                </td>
                                <td class="price-col">
                                    <span>{{ formData.billingAddress.name }}</span>
                                    <Field name="billingAddress.name" as="input" type="hidden"
                                           v-model="formData.billingAddress.name"/>
                                    <ErrorMessage class="form-error" name="billingAddress.name"/>
                                </td>
                            </tr>
                            <tr>
                                <td class="product-col">
                                    <h3 class="product-title">
                                        {{ $trans('Email address') }}
                                    </h3>
                                </td>
                                <td class="price-col">
                                    <span>{{ formData.billingAddress.email }}</span>
                                    <Field name="billingAddress.email" as="input" type="hidden"
                                           v-model="formData.billingAddress.email"/>
                                    <ErrorMessage class="form-error" name="billingAddress.email"/>
                                </td>
                            </tr>
                            <tr>
                                <td class="product-col">
                                    <h3 class="product-title">
                                        {{ $trans('Phone') }}
                                    </h3>
                                </td>
                                <td class="price-col">
                                    <span>{{ formData.billingAddress.phone }}</span>
                                    <Field name="billingAddress.phone" as="input" type="hidden"
                                           v-model="formData.billingAddress.phone"/>
                                    <ErrorMessage class="form-error" name="billingAddress.phone"/>
                                </td>
                            </tr>
                            <tr>
                                <td class="product-col">
                                    <h3 class="product-title">
                                        {{ $trans('Tax number') }}
                                    </h3>
                                </td>
                                <td class="price-col">
                                    <span>{{
                                            (formData.billingAddress.taxNumber) ? formData.billingAddress.taxNumber : '-'
                                        }}</span>
                                    <Field name="billingAddress.taxNumber" as="input" type="hidden"
                                           v-model="formData.billingAddress.taxNumber"/>
                                    <ErrorMessage class="form-error" name="billingAddress.taxNumber"/>
                                </td>
                            </tr>
                            <tr>
                                <td class="product-col">
                                    <h3 class="product-title">
                                        {{ $trans('Company name') }}
                                    </h3>
                                </td>
                                <td class="price-col">
                                    <span>{{
                                            (formData.billingAddress.companyName) ? formData.billingAddress.companyName : '-'
                                        }}</span>
                                    <Field name="billingAddress.companyName" as="input" type="hidden"
                                           v-model="formData.billingAddress.companyName"/>
                                    <ErrorMessage class="form-error" name="billingAddress.companyName"/>
                                </td>
                            </tr>
                            <tr>
                                <td class="product-col">
                                    <h3 class="product-title">
                                        {{ $trans('Address') }}
                                    </h3>
                                </td>
                                <td class="price-col">
                                <span>{{ formData.billingAddress.country.zip }} {{ formData.billingAddress.city }},
                                    {{ formData.billingAddress.street }} {{ formData.billingAddress.houseNumber }}
                                    {{
                                        formData.billingAddress.houseNumberInfo
                                    }}<br/>{{ formData.billingAddress.country.title }}
                                </span>
                                    <Field name="billingAddress.country" as="input" type="hidden"
                                           v-model="formData.billingAddress.country"/>
                                    <ErrorMessage class="form-error" name="billingAddress.country"/>
                                    <Field name="billingAddress.zip" as="input" type="hidden"
                                           v-model="formData.billingAddress.zip"/>
                                    <ErrorMessage class="form-error" name="billingAddress.zip"/>
                                    <Field name="billingAddress.city" as="input" type="hidden"
                                           v-model="formData.billingAddress.city"/>
                                    <ErrorMessage class="form-error" name="billingAddress.city"/>
                                    <Field name="billingAddress.houseNumber" as="input" type="hidden"
                                           v-model="formData.billingAddress.houseNumber"/>
                                    <ErrorMessage class="form-error" name="billingAddress.houseNumber"/>
                                    <Field name="billingAddress.houseNumberInfo" as="input" type="hidden"
                                           v-model="formData.billingAddress.houseNumberInfo"/>
                                    <ErrorMessage class="form-error" name="billingAddress.houseNumberInfo"/>
                                </td>
                            </tr>
                            <tr>
                                <td class="product-col">
                                    <h3 class="product-title">
                                        {{ $trans('Comment for Boxfice') }}
                                    </h3>
                                </td>
                                <td class="price-col">
                                    <span>{{ (formData.commentForUs) ? formData.commentForUs : '-' }}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- End .cart-summary -->
                </div>
                <!-- End .col-lg-6 -->

                <div class="col-lg-6">
                    <porto-loading-overlay v-if="loading"></porto-loading-overlay>
                    <div class="order-summary">
                        <h3>{{ $trans('Shipping Address') }}
                            <span class="float-right">
                            <a href="/penztar" class="btn btn-dark btn-sm">
                                <i class="fas fa-pencil-alt"></i>
                            </a>
                        </span>
                        </h3>

                        <table class="table table-mini-cart">
                            <tbody>
                            <tr>
                                <td class="product-col">
                                    <h3 class="product-title">
                                        {{ $trans('Name') }}
                                    </h3>
                                </td>
                                <td class="price-col">
                                    <span>{{ formData.shippingAddress.name }}</span>
                                    <Field name="shippingAddress.name" as="input" type="hidden"
                                           v-model="formData.shippingAddress.name"/>
                                    <ErrorMessage class="form-error" name="shippingAddress.name"/>
                                </td>
                            </tr>
                            <tr>
                                <td class="product-col">
                                    <h3 class="product-title">
                                        {{ $trans('Email address') }}
                                    </h3>
                                </td>
                                <td class="price-col">
                                    <span>{{ formData.shippingAddress.email }}</span>
                                    <Field name="shippingAddress.email" as="input" type="hidden"
                                           v-model="formData.shippingAddress.email"/>
                                    <ErrorMessage class="form-error" name="shippingAddress.email"/>
                                </td>
                            </tr>
                            <tr>
                                <td class="product-col">
                                    <h3 class="product-title">
                                        {{ $trans('Phone') }}
                                    </h3>
                                </td>
                                <td class="price-col">
                                    <span>{{ formData.shippingAddress.phone }}</span>
                                    <Field name="shippingAddress.phone" as="input" type="hidden"
                                           v-model="formData.shippingAddress.phone"/>
                                    <ErrorMessage class="form-error" name="shippingAddress.phone"/>
                                </td>
                            </tr>
                            <tr>
                                <td class="product-col">
                                    <h3 class="product-title">
                                        {{ $trans('Address') }}
                                    </h3>
                                </td>
                                <td class="price-col">
                                <span>{{ formData.shippingAddress.country.zip }} {{ formData.shippingAddress.city }},
                                    {{ formData.shippingAddress.street }} {{ formData.shippingAddress.houseNumber }}
                                    {{
                                        formData.shippingAddress.houseNumberInfo
                                    }}<br/>{{ formData.shippingAddress.country.title }}
                                </span>
                                    <Field name="shippingAddress.country" as="input" type="hidden"
                                           v-model="formData.shippingAddress.country"/>
                                    <ErrorMessage class="form-error" name="shippingAddress.country"/>
                                    <Field name="shippingAddress.zip" as="input" type="hidden"
                                           v-model="formData.shippingAddress.zip"/>
                                    <ErrorMessage class="form-error" name="shippingAddress.zip"/>
                                    <Field name="shippingAddress.city" as="input" type="hidden"
                                           v-model="formData.shippingAddress.city"/>
                                    <ErrorMessage class="form-error" name="shippingAddress.city"/>
                                    <Field name="shippingAddress.houseNumber" as="input" type="hidden"
                                           v-model="formData.shippingAddress.houseNumber"/>
                                    <ErrorMessage class="form-error" name="shippingAddress.houseNumber"/>
                                    <Field name="shippingAddress.houseNumberInfo" as="input" type="hidden"
                                           v-model="formData.shippingAddress.houseNumberInfo"/>
                                    <ErrorMessage class="form-error" name="shippingAddress.houseNumberInfo"/>
                                </td>
                            </tr>
                            <tr>
                                <td class="product-col">
                                    <h3 class="product-title">
                                        {{ $trans('Payment method') }}
                                    </h3>
                                </td>
                                <td class="price-col">
                                    <span>{{ paymentMethodName }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="product-col">
                                    <h3 class="product-title">
                                        {{ $trans('Shipping method') }}
                                    </h3>
                                </td>
                                <td class="price-col">
                                    <span>{{ shippingMethodName }}</span>
                                </td>
                            </tr>

                            <tr>
                                <td class="product-col">
                                    <h3 class="product-title">
                                        {{ $trans('Comment for courier') }}
                                    </h3>
                                </td>
                                <td class="price-col">
                                    <span>{{ (formData.commentForCourier) ? formData.commentForCourier : '-' }}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- End .cart-summary -->
                </div>
                <!-- End .col-lg-6 -->

                <div class="col-lg-12">
                    <porto-loading-overlay v-if="loading"></porto-loading-overlay>
                    <div class="order-summary">
                        <h3>{{ $trans('YOUR ORDER') }}</h3>

                        <div class="cart-table-container">
                            <table class="table table-cart">
                                <thead>
                                <tr>
                                    <th class="thumbnail-col"></th>
                                    <th class="product-col">{{ $trans('Product') }}</th>
                                    <th style="width: 1%; white-space: nowrap" class="qty-col">{{
                                            $trans('Quantity')
                                        }}
                                    </th>
                                    <th style="width: 1%; white-space: nowrap" class="price-col">{{
                                            $trans('Unit price')
                                        }}
                                    </th>
                                    <th style="width: 1%; white-space: nowrap" class="price-col">{{
                                            $trans('Net price')
                                        }}
                                    </th>
                                    <th style="width: 1%; white-space: nowrap" class="price-col">{{
                                            $trans('Tax')
                                        }}
                                    </th>
                                    <th style="width: 1%; white-space: nowrap" class="text-right">
                                        {{ $trans('Gross price') }}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr class="product-row" v-for="(item, key) in items" :key="item.id">
                                    <td>
                                        <figure class="product-image-container">
                                            <a v-if="item.associatedModel.modelType === 'App\\Models\\Product'" :href="'/termek/'+item.associatedModel.slug+'-p'+item.associatedModel.id"
                                               class="product-image">
                                                <img :src="item.associatedModel.mainImage" alt="product">
                                            </a>
                                            <a v-else :href="'/termekcsomag/'+item.associatedModel.slug+'-p'+item.associatedModel.id"
                                               class="product-image">
                                                <img :src="item.associatedModel.mainImage" alt="product">
                                            </a>
                                        </figure>
                                    </td>
                                    <td class="product-col">
                                        <h5 class="product-title">
                                            <a v-if="item.associatedModel.modelType === 'App\\Models\\Product'" :href="'/termek/'+item.associatedModel.slug+'-p'+item.associatedModel.id">{{
                                                    item.name
                                                }}</a>
                                            <a v-else :href="'/termekcsomag/'+item.associatedModel.slug+'-p'+item.associatedModel.id">{{
                                                    item.name
                                                }}</a>
                                        </h5>
                                    </td>
                                    <td style="width: 1%; white-space: nowrap">
                                        <div class="product-single-qty">
                                            {{ item.quantity }} <span v-if="item.associatedModel.modelType === 'App\\Models\\Product'">{{ item.associatedModel.unit.name }}</span><span v-else>{{ (Array.isArray(item.associatedModel.unit)) ? item.associatedModel.unit[0].name : item.associatedModel.unit.name }}</span>
                                        </div><!-- End .product-single-qty -->
                                    </td>
                                    <td style="width: 1%; white-space: nowrap" class="text-right">
                                        {{ $priceFormatter.formatPriceText(item.price) }}
                                    </td>
                                    <td style="width: 1%; white-space: nowrap" class="text-right">
                                        {{ $priceFormatter.formatPriceText(item.netPrice * item.quantity) }}
                                    </td>
                                    <td style="width: 1%; white-space: nowrap" class="text-right">
                                        {{ $priceFormatter.formatPriceText(item.tax * item.quantity) }}
                                    </td>
                                    <td style="width: 1%; white-space: nowrap" class="text-right"><span
                                        class="subtotal-price">{{
                                            $priceFormatter.formatPriceText(item.price * item.quantity)
                                        }}</span></td>
                                </tr>
                                </tbody>

                                <tfoot>
                                <tr v-if="coupon">
                                    <th style="width: 1%; white-space: nowrap" colspan="6" class="price-col">
                                        {{ $trans('Coupon') }}:
                                    </th>
                                    <th style="width: 1%; white-space: nowrap" class="price-col text-right">
                                        {{ coupon.couponCode }} ({{ coupon.originalValue }})
                                    </th>
                                </tr>
                                <tr>
                                    <th style="width: 1%; white-space: nowrap" colspan="4" class="price-col">
                                        {{ $trans('Subtotal') }}:
                                    </th>
                                    <th style="width: 1%; white-space: nowrap" class="price-col text-right">
                                        {{ $priceFormatter.formatPriceText(netSubtotal) }}
                                    </th>
                                    <th style="width: 1%; white-space: nowrap" class="price-col text-right">
                                        {{ $priceFormatter.formatPriceText(tax) }}
                                    </th>
                                    <th style="width: 1%; white-space: nowrap" class="price-col text-right">
                                        {{ $priceFormatter.formatPriceText(subtotal) }}
                                    </th>
                                </tr>
                                <tr v-for="promo in promos">
                                    <th style="width: 1%; white-space: nowrap" colspan="6" class="price-col">
                                        {{ promo.name }}:
                                    </th>
                                    <th style="width: 1%; white-space: nowrap" class="price-col text-right">
                                        {{ $priceFormatter.formatPriceText(promo.value) }}
                                    </th>
                                </tr>
                                <tr>
                                    <th style="width: 1%; white-space: nowrap" colspan="6" class="price-col">
                                        {{ shippingMethodName }}:
                                    </th>
                                    <th style="width: 1%; white-space: nowrap" class="price-col text-right">
                                        {{ $priceFormatter.formatPriceText(shippingMethodFee) }}
                                    </th>
                                </tr>
                                <tr v-if="shippingMethodName.includes('MPL')">
                                    <th style="width: 1%; white-space: nowrap" colspan="6" class="price-col">
                                        Kezelési költség:
                                    </th>
                                    <th style="width: 1%; white-space: nowrap" class="price-col text-right">
                                        {{ $priceFormatter.formatPriceText(10000) }}
                                    </th>
                                </tr>
                                <tr v-if="paymentMethodFee > 0">
                                    <th style="width: 1%; white-space: nowrap" colspan="6" class="price-col">
                                        {{ paymentMethodName }}:
                                    </th>
                                    <th style="width: 1%; white-space: nowrap" class="price-col text-right">
                                        {{ $priceFormatter.formatPriceText(paymentMethodFee) }}
                                    </th>
                                </tr>
                                <tr>
                                    <th style="width: 1%; white-space: nowrap" colspan="6" class="price-col">
                                        {{ $trans('Total') }}:
                                    </th>
                                    <th style="width: 1%; white-space: nowrap" class="price-col text-right">
                                        {{ $priceFormatter.formatPriceText(total) }}
                                    </th>
                                </tr>
                                </tfoot>
                            </table>

                            <div class="d-flex align-items-center justify-content-end">
                                <button type="submit" @click="submitForm" class="btn btn-dark"
                                        :class="{'disabled pointer-events-none': subtotal < minimumOrderValue}">
                                    {{ $trans('Place order') }}
                                </button>
                            </div>
                        </div><!-- End .cart-table-container -->
                    </div>
                </div><!-- End .col-lg-8 -->
            </div>
        </Form>
        <!-- End .row -->
    </div>
</template>

<script>

import hu from 'i18n-iso-countries/langs/hu.json'
import PortoLoadingOverlay from "./PortoLoadingOverlay.vue";
import {formatTaxNumber} from "../formatters/taxNumberFormatter";
import {ErrorMessage, Field, Form} from "vee-validate";
import {setupVeeValidate} from "../utils/veeValidate";
import {registerLocale} from "i18n-iso-countries";

export default {
    mounted() {
        setupVeeValidate();
        registerLocale(hu);
        this.initData()
    },
    props: {
        minimumOrderValue: {
            type: Number,
            required: true
        }
    },
    components: {
        PortoLoadingOverlay,
        ErrorMessage,
        Field,
        Form,
    },
    data() {
        return {
            loading: false,
            isLoggedIn: false,
            formData: {
                billingAddress: {
                    name: '',
                    email: '',
                    phone: '',
                    country: '',
                    zip: '',
                    city: '',
                    street: '',
                    houseNumber: '',
                    houseNumberInfo: '',
                    companyName: '',
                    taxNumber: '',
                },
                shippingAddress: {
                    name: '',
                    email: '',
                    phone: '',
                    country: '',
                    zip: '',
                    city: '',
                    street: '',
                    houseNumber: '',
                    houseNumberInfo: '',
                    companyName: '',
                    taxNumber: '',
                },
                commentForUs: '',
                commentForCourier: '',
                createUser: false,
                newPassword: '',
                acceptTerms: false,
                acceptSimplePayStatement: false,
                subscribeToTheNewsletter: false
            },
            formSchema: {
                billingName: {
                    required: true,
                },
                billingEmail: {
                    required: true,
                    email: true,
                },
                billingPhone: {
                    required: true,
                    phone: true,
                },
                billingCompanyName: {},
                billingTaxNumber: {
                    taxNumber: [this.selectedBillingCountryCode]
                },
                billingCountry: {
                    required: true,
                },
                billingZip: {
                    required: true,
                },
                billingCity: {
                    required: true,
                },
                billingStreet: {
                    required: true,
                },
                billingHouseNumber: {
                    required: true,
                },
                billingHouseNumberInfo: {},
                shippingName: {
                    required: true,
                },
                shippingEmail: {
                    required: true,
                    email: true,
                },
                shippingPhone: {
                    required: true,
                    phone: true,
                },
                shippingCompanyName: {},
                shippingTaxNumber: {
                    taxNumber: [this.selectedShippingCountryCode]
                },
                shippingCountry: {
                    required: true,
                },
                shippingZip: {
                    required: true,
                },
                shippingCity: {
                    required: true,
                },
                shippingStreet: {
                    required: true,
                },
                shippingHouseNumber: {
                    required: true,
                },
                shippingHouseNumberInfo: {},
                commentForUs: {},
                commentForCourier: {},
                acceptTerms: {
                    terms: true
                },
                acceptSimplePayStatement: (value) => {

                    if (this.selectedPaymentMethodAlias !== "simplepay") {
                        return true;
                    } else {
                        if (typeof value == "undefined") {
                            return this.$trans('youMustAcceptSimplePayStatement')
                        }
                    }

                    if (!value) {
                        return this.$trans('youMustAcceptSimplePayStatement')
                    }

                    return true
                },
                subscribeToTheNewsletter: {},
            },
            items: '',
            itemsCount: 0,
            netSubtotal: 0,
            subtotal: 0,
            total: 0,
            tax: 0,
            shippingMethods: [],
            selectedShippingMethodId: '',
            paymentMethods: [],
            selectedPaymentMethod: '',
            paymentMethodName: '',
            paymentMethodFee: 0,
            shippingMethodName: '',
            shippingMethodFee: 0,
            coupon: '',
            promos: [],
        }
    },
    methods: {
        initData() {
            this.loading = true
            let storedCheckoutData = this.$storageHelper.getLocalStorageItemWithExpiry('checkoutFormData')
            this.formData = JSON.parse(storedCheckoutData)

            this.initCart()
        },
        initCart() {
            this.$store.dispatch('fetchCartItems').then(response => {
                this.items = response.data.entities
                this.netSubtotal = response.data.netSubtotal
                this.subtotal = response.data.subtotal
                this.total = response.data.total
                this.tax = response.data.tax
                this.shippingMethods = response.data.shippingMethods
                this.selectedShippingMethodId = response.data.selectedShippingMethodId
                this.paymentMethods = response.data.paymentMethods
                this.selectedPaymentMethod = response.data.selectedPaymentMethod
                this.itemsCount = Object.keys(this.items).length
                this.coupon = response.data.coupon
                this.promos = response.data.promos

                let selectedPaymentMethod = this.paymentMethods.filter(paymentMethod => {
                    return paymentMethod.id === this.selectedPaymentMethod.id
                })
                this.paymentMethodName = selectedPaymentMethod[0]["name"]
                /**
                 * TODO ez így kicsit proli
                 */
                this.paymentMethodFee = selectedPaymentMethod[0]["price"] * 1.27

                let selectedShippingMethod = this.shippingMethods.filter(shippingMethod => {
                    return shippingMethod.id === this.selectedShippingMethodId
                })
                this.shippingMethodName = selectedShippingMethod[0]["name"]
                /**
                 * TODO ez így kicsit proli
                 */
                this.shippingMethodFee = selectedShippingMethod[0]["price"] * 1.27

                if (this.itemsCount === 0) {
                    window.location.href = '/termekek'
                }
            }).finally(() => {
                this.loading = false
            })
        },
        submitForm() {
            this.loading = true
            let fd = JSON.parse(JSON.stringify(this.formData))
            fd.billingAddress.country = this.formData.billingAddress.country.iso2
            fd.shippingAddress.country = this.formData.shippingAddress.country.iso2

            this.$store.dispatch('storeOrder', fd).then(response => {
                this.$storageHelper.deleteLocalStorageItem('checkoutFormData')

                let items = []
                Object.keys(this.items).forEach(key => {
                    items.push({
                        item_id: this.items[key].associatedModel.id,
                        item_name: this.items[key].name,
                        price: this.$priceFormatter.formatPriceNumber(this.items[key].price),
                        item_category: this.items[key].associatedModel.category.name,
                        quantity: this.items[key].quantity
                    })
                })

                if (import.meta.env.VITE_APP_ENV === 'production') {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'purchase',
                        ecommerce: {
                            currency: 'HUF',
                            value: this.$priceFormatter.formatPriceNumber(response.data.entity.grossTotal),
                            items: items,
                            transaction_id: 'BXFC-' + response.data.entity.id
                        }
                    });

                    gtag('event', 'conversion', {
                        'send_to': 'AW-11228331883/cDVrCOG72-EYEOv-iuop',
                        'value': this.$priceFormatter.formatPriceNumber(response.data.entity.grossTotal),
                        'currency': 'HUF',
                        'transaction_id': 'BXFC-' + response.data.entity.id
                    });
                }

                window.location.href = '/sikeres-megrendeles?key=' + btoa(response.data.entity.id)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 422) {
                    this.$refs.orderFinalizationForm.setErrors(error.response.data.errors);
                } else if (error.response.status === 400) {
                    Porto.miniPopup.open({
                        name: 'Hiba!',
                        nameLink: '#',
                        imageSrc: '/img/error.png',
                        imageLink: '#',
                        content: error.response.data.message,
                        action: '',
                    })
                } else {
                    if (error.response.data.message !== 'error') {
                        Porto.miniPopup.open({
                            name: 'Hiba!',
                            nameLink: '#',
                            imageSrc: '/img/error.png',
                            imageLink: '#',
                            content: error.response.data.message,
                            action: '',
                        })

                        window.setTimeout(() => {
                            //window.location.href = '/kosar'
                        }, 3000)
                    } else {
                        Porto.miniPopup.open({
                            name: 'Hiba!',
                            nameLink: '#',
                            imageSrc: '/img/error.png',
                            imageLink: '#',
                            content: this.$trans('OrderCanNotStoredErrorMessage'),
                            action: '',
                        })
                    }
                }
            }).finally(() => {
                this.loading = false
            })
            //window.location.href = '/rendeles-veglegesitese'
        },
    }
}
</script>
