<template>
    <a href="javascript:void(0)" v-if="variant === 'product-list'" @click="addToWishlist" :class="{'active': isOnWishlist}" class="btn-icon-wish" :title="(isOnWishlist) ? $trans('On Wishlist') : $trans('Add to Wishlist')">
        <i v-if="isOnWishlist" class="icon-heart-1"></i>
        <i v-else class="icon-heart"></i>
    </a>
    <span v-if="variant === 'product-page'">
        <a href="javascript:void(0)" @click="addToWishlist" :class="{'added-wishlist': isOnWishlist}" class="btn-icon-wish add-wishlist" :title="(isOnWishlist) ? $trans('On Wishlist') : $trans('Add to Wishlist')">
            <i class="icon-wishlist-2"></i>
            <span v-if="!isOnWishlist">{{ $trans('Add to Wishlist') }}</span>
            <span v-else>{{ $trans('On Wishlist') }}</span>
        </a>
    </span>
</template>

<script>
export default {
    props: {
        product: {
            required: true
        },
        variant: {
            required: false,
            default: 'product-list'
        }
    },
    data(){
        return {
            productObject: '',
            isOnWishlist: false
        }
    },
    created(){
        this.productObject = JSON.parse(this.product);
        this.isOnWishlist = Boolean(this.productObject.isOnWishlist)
    },
    methods: {
        addToWishlist(){
            if(this.isOnWishlist){
                this.$store.dispatch('removeFromWishlist', {id: this.productObject.id}).then(() => {
                    this.isOnWishlist = false
                    Porto.miniPopup.open( {
                        name: this.productObject.name,
                        nameLink: '/termek'+this.productObject.slug+'/-p'+this.productObject.id,
                        imageSrc: this.productObject.mainImage,
                        imageLink: '/termek'+this.productObject.slug+'/-p'+this.productObject.id,
                        content: this.$trans('has been removed from your wishlist.'),
                        action: '<a href="/kivansaglista" class="btn viewcart">'+this.$trans('View wishlist')+'</a>',
                    } );

                    let price = this.productObject.price
                    if(this.productObject.salePrice > 0){
                        price = this.productObject.salePrice
                    }

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'remove_from_wishlist',
                        ecommerce: {
                            // TODO: ha lesz több valuta, tegyük dinamikussá
                            currency: 'HUF',
                            value: this.$priceFormatter.formatPriceNumber(price*(1+(this.productObject.taxRate/100))),
                            items: [
                                {
                                    item_name: this.productObject.name,
                                    price: price*(1+(this.productObject.taxRate/100)),
                                    item_category: this.productObject.category.name,
                                    quantity: 1
                                },
                            ]
                        }
                    })

                }).catch(err => {
                    // TODO: lekezelni
                    console.log(err)
                })
            }else{
                this.$store.dispatch('addToWishlist', {id: this.productObject.id}).then(() => {
                    this.isOnWishlist = true
                    Porto.miniPopup.open( {
                        name: this.productObject.name,
                        nameLink: '/termek'+this.productObject.slug+'/-p'+this.productObject.id,
                        imageSrc: this.productObject.mainImage,
                        imageLink: '/termek'+this.productObject.slug+'/-p'+this.productObject.id,
                        content: this.$trans('has been added to your wishlist.'),
                        action: '<a href="/kivansaglista" class="btn viewcart">'+this.$trans('View wishlist')+'</a>',
                    } );

                    let price = this.productObject.price
                    if(this.productObject.salePrice > 0){
                        price = this.productObject.salePrice
                    }

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'add_to_wishlist',
                        ecommerce: {
                            // TODO: ha lesz több valuta, tegyük dinamikussá
                            currency: 'HUF',
                            value: this.$priceFormatter.formatPriceNumber(price*(1+(this.productObject.taxRate/100))),
                            items: [
                                {
                                    item_name: this.productObject.name,
                                    price: price*(1+(this.productObject.taxRate/100)),
                                    item_category: this.productObject.category.name,
                                    quantity: 1
                                },
                            ]
                        }
                    })

                }).catch(err => {
                    // TODO: lekezelni
                    console.log(err)
                })
            }
        }
    }
}
</script>
