<template>
    <a href="javascript:void(0)" @click="addToCart" v-if="variant === 'product-list' && type === 'product' && modelObject.stock > 0 && modelObject.stockStatus === 'inStock'"
       class="btn-icon btn-add-cart product-type-simple horizontal-shake horizontal-shake-2t"><i v-if="inCart === 0"
        class="icon-shopping-cart"></i><i v-else class="icon-check"></i> {{ cartButtonText }}&nbsp;<span v-if="parseInt(inCart) > 0">({{ inCart }})</span></a>
    <a href="javascript:void(0)" @click="addToCart" v-if="variant === 'product-list' && type === 'package'"
       class="btn-icon btn-add-cart product-type-simple horizontal-shake horizontal-shake-2t"><i v-if="inCart === 0"
       class="icon-shopping-cart"></i><i v-else class="icon-check"></i>{{ cartButtonText }}&nbsp;<span v-if="parseInt(inCart) > 0">({{ inCart }})</span></a>

    <button @click="addToCart" v-if="variant === 'wishlist'"
            class="btn btn-dark btn-add-cart product-type-simple btn-shop">
        {{ cartButtonText }}&nbsp;<span v-if="parseInt(inCart) > 0">({{ inCart }})</span>
    </button>
    <button @click="addToCart" v-if="variant === 'mini'"
            class="btn btn-dark btn-add-cart product-type-simple btn-shop btn-mini" :aria-label="cartButtonText">
        <i v-if="parseInt(inCart) === 0"
           class="icon-shopping-cart"></i><i v-else class="icon-check"></i>&nbsp;<span v-if="inCart > 0">({{ inCart }})</span>
    </button>
    <div class="product-action" v-if="variant === 'product-page' && type === 'product' && modelObject.stock > 0 && modelObject.stockStatus === 'inStock'">
        <div class="product-single-qty">
            <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                <span class="input-group-btn input-group-prepend">
                    <button @click="removeOne"
                            class="btn btn-outline btn-down-icon bootstrap-touchspin-down"
                            type="button"></button>
                </span>
                <input class="horizontal-quantity form-control" v-model="quantityObject"
                       type="number" @change="validateQuantity">
                <span class="input-group-btn input-group-append">
                    <button @click="addOne"
                            class="btn btn-outline btn-up-icon bootstrap-touchspin-up"
                            type="button"></button>
                </span>
            </div>
        </div><!-- End .product-single-qty -->

        <a href="javascript:void(0)" @click="addToCart" class="btn btn-dark add-cart icon-shopping-cart mr-2 rise-shake"
           :title="$trans('Add to Cart')">{{ cartButtonText }}&nbsp;<span v-if="inCart > 0">({{ inCart }})</span></a>

        <a href="/kosar" class="btn btn-gray view-cart d-none">{{ $trans('View cart') }}</a>
    </div>
    <div class="product-action" v-if="variant === 'product-page' && type === 'package'">
        <div class="product-single-qty">
            <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                <span class="input-group-btn input-group-prepend">
                    <button @click="removeOne"
                            class="btn btn-outline btn-down-icon bootstrap-touchspin-down"
                            type="button"></button>
                </span>
                <input class="horizontal-quantity form-control" v-model="quantityObject"
                       type="number" @change="validateQuantity">
                <span class="input-group-btn input-group-append">
                    <button @click="addOne"
                            class="btn btn-outline btn-up-icon bootstrap-touchspin-up"
                            type="button"></button>
                </span>
            </div>
        </div><!-- End .product-single-qty -->

        <a href="javascript:void(0)" @click="addToCart" class="btn btn-dark add-cart icon-shopping-cart mr-2 rise-shake"
           :title="$trans('Add to Cart')">{{ cartButtonText }}&nbsp;<span v-if="inCart > 0">({{ inCart }})</span></a>

        <a href="/kosar" class="btn btn-gray view-cart d-none">{{ $trans('View cart') }}</a>
    </div><!-- End .product-action -->
</template>

<script>
import { Modal } from "bootstrap";

export default {
    props: {
        model: {
            required: true
        },
        type: {
            required: true
        },
        quantity: {
            required: false,
            default: 1
        },
        variant: {
            required: false,
            default: 'product-list'
        }
    },
    mounted() {
        this.quantityObject = this.quantity
        let model;
        if (typeof this.model !== "object") {
            model = JSON.parse(this.model)
        } else {
            model = this.model
        }

        this.modelObject = model
        this.minimumOrderQuantity = parseInt(this.modelObject.minimumOrderQuantity)
        if (this.minimumOrderQuantity > 1) {
            this.quantityObject = this.minimumOrderQuantity
        }
        this.minimumOrderMultiply = this.modelObject.minimumOrderMultiply
        this.inCart = model.inCart

        if(this.inCart > 0) {
            this.cartButtonText = this.$trans('In cart')
        }

    },
    data() {
        return {
            modelObject: '',
            quantityObject: 0,
            minimumOrderQuantity: 1,
            minimumOrderMultiply: false,
            addedToCartModal: null,
            cartButtonText: this.$trans('Add to Cart'),
            inCart: 0,
        }
    },
    methods: {
        addToCart() {
            if (this.validateQuantity()) {
                this.$store.dispatch('addToCart', {
                    id: this.modelObject.id,
                    type: this.type,
                    quantity: this.quantityObject
                }).then(response => {
                    let headerCart = this.$root.$refs["headerCart"];
                    if(headerCart) {
                        headerCart.initData()
                    }
                    let mobileCartIcon = this.$root.$refs["mobileCartIcon"];
                    if(mobileCartIcon) {
                        mobileCartIcon.initData()
                    }

                    let modal = this.$root.$refs['addedToCartModal'].$refs['modal']

                    this.$root.$refs['addedToCartModal'].initData(response.data.item, response.data.relatedProducts, response.data.quantity)

                    this.addedToCartModal = new Modal(modal, { keyboard: false });
                    this.addedToCartModal.show();

                    let price = this.modelObject.price
                    if(this.modelObject.salePrice > 0){
                        price = this.modelObject.salePrice
                    }

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'add_to_cart',
                        ecommerce: {
                            // TODO: ha lesz több valuta, tegyük dinamikussá
                            currency: 'HUF',
                            value: this.$priceFormatter.formatPriceNumber(this.quantityObject*price*(1+(this.modelObject.taxRate/100))),
                            items: [
                                {
                                    item_name: this.modelObject.name,
                                    price: this.$priceFormatter.formatPriceNumber(price*(1+(this.modelObject.taxRate/100))),
                                    item_category: this.modelObject.category.name,
                                    quantity: this.quantityObject
                                },
                            ]
                        }
                    })

                    this.inCart = this.quantityObject

                }).catch(err => {
                    if(err.response){
                        Porto.miniPopup.open({
                            name: 'Hiba!',
                            nameLink: '#',
                            imageSrc: '/img/error.png',
                            imageLink: '#',
                            content: err.response.data.message,
                            action: '',
                        });
                    }else{
                        console.log(err)
                    }
                })
            }
        },
        addOne() {
            if (this.minimumOrderMultiply) {
                this.quantityObject += this.minimumOrderQuantity
            } else {
                this.quantityObject++
            }
        },
        removeOne() {
            if (this.quantityObject > 0) {
                if (this.minimumOrderMultiply) {
                    this.quantityObject -= this.minimumOrderQuantity
                } else {
                    this.quantityObject--
                }
            }
        },
        validateQuantity() {
            if (this.quantityObject > 0) {
                if (this.minimumOrderMultiply && this.minimumOrderQuantity > 1) {
                    if (this.quantityObject % this.minimumOrderQuantity !== 0) {
                        Porto.miniPopup.open({
                            name: 'Hiba!',
                            nameLink: '#',
                            imageSrc: '/img/error.png',
                            imageLink: '#',
                            content: 'A mennyiség csak a(z) ' + this.minimumOrderQuantity + ' többszöröse lehet.',
                            action: '',
                        });

                        this.quantityObject = this.roundNearest(this.quantityObject)

                        return false
                    }
                }
            }

            return true
        },
        roundNearest(number) {
            let rounded = Math.round(number / this.minimumOrderQuantity) * this.minimumOrderQuantity;
            if (rounded < this.minimumOrderQuantity) {
                rounded = parseInt(this.minimumOrderQuantity)
            }
            return rounded
        }
    }
}
</script>
