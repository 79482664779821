import helpers from '../../../helpers'

const userMutations = {
  SET_USER_DATA(state, userData) {
    state.user = userData
    helpers.setLocalStorageItem('userData', JSON.stringify(userData))
  },
  CLEAR_USER_DATA(state) {
    state.token = ''
    state.user = null
    helpers.deleteLocalStorageItem('userData')
  },
}

export default userMutations
