import cartActions from "./cartActions";
import wishlistActions from "./wishlistActions";
import authActions from "./authActions";
import addressActions from "./addressActions";
import taxNumberActions from "./taxNumberActions";
import orderActions from "./orderActions";
import searchActions from "./searchActions";

const actions = {
    ...cartActions,
    ...wishlistActions,
    ...authActions,
    ...addressActions,
    ...taxNumberActions,
    ...orderActions,
    ...searchActions,
}

export default actions
