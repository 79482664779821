<template>
    <div class="modal fade" ref="modal" id="addedToCartModal" tabindex="-1" role="dialog"
         aria-labelledby="addedToCartModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title" id="addedToCartModalLabel"><i class=""></i>
                        {{ $trans('Product has been added to your cart.') }}</h2>
                    <button type="button" class="close" data-dismiss="modal" :aria-label="$trans('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <porto-loading-overlay v-if="loading"></porto-loading-overlay>
                    <div class="cart-table-container">
                        <table class="table table-cart">
                            <thead>
                            <tr>
                                <th class="thumbnail-col"></th>
                                <th class="product-col">{{ $trans('Product') }}</th>
                                <th class="price-col">{{ $trans('Price') }}</th>
                                <th class="qty-col">{{ $trans('Quantity') }}</th>
                                <th class="text-right">{{ $trans('Subtotal') }}</th>
                            </tr>
                            </thead>
                            <tbody v-if="Object.keys(this.item).length > 0">
                            <tr class="product-row">
                                <td>
                                    <figure class="product-image-container">
                                        <a v-if="item.modelType === 'App\\Models\\Product'"
                                           :href="'/termek/'+item.associatedModel.slug+'-p'+item.associatedModel.id"
                                           class="product-image">
                                            <img :src="item.associatedModel.mainImage" alt="product">
                                        </a>
                                        <a v-else
                                           :href="'/termekcsomag/'+item.associatedModel.slug+'-p'+item.associatedModel.id"
                                           class="product-image">
                                            <img :src="item.associatedModel.mainImage" alt="product">
                                        </a>

                                        <a href="javascript:void(0);" @click="removeFromCart()"
                                           class="btn-remove icon-cancel" :title="$trans('Remove Product')"></a>
                                    </figure>
                                </td>
                                <td class="product-col">
                                    <h5 class="product-title">
                                        <a v-if="item.associatedModel.modelType === 'App\\Models\\Product'"
                                           :href="'/termek/'+item.associatedModel.slug+'-p'+item.associatedModel.id">{{
                                                item.name
                                            }}</a>
                                        <a v-else
                                           :href="'/termekcsomag/'+item.associatedModel.slug+'-p'+item.associatedModel.id">{{
                                                item.name
                                            }}</a>
                                        <small>{{ $trans(item.associatedModel.stockStatus) }}</small>
                                    </h5>
                                </td>
                                <td>{{
                                        $priceFormatter.formatPriceText(item.price * ((1 + item.associatedModel.taxRate) / 100))
                                    }}
                                </td>
                                <td>
                                    <div class="product-single-qty">
                                        <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                            <span class="input-group-btn input-group-prepend">
                                                <button @click="removeOne()"
                                                        class="btn btn-outline btn-down-icon bootstrap-touchspin-down"
                                                        type="button"></button>
                                            </span>
                                            <input class="horizontal-quantity form-control" v-model="quantity"
                                                   type="number" @change="validateQuantity()">
                                            <span class="input-group-btn input-group-append">
                                                <button @click="addOne()"
                                                        class="btn btn-outline btn-up-icon bootstrap-touchspin-up"
                                                        type="button"></button>
                                            </span>
                                        </div>
                                    </div><!-- End .product-single-qty -->
                                </td>
                                <td class="text-right"><span class="subtotal-price">{{
                                        $priceFormatter.formatPriceText(item.price * ((1 + item.associatedModel.taxRate) / 100) * quantity)
                                    }}</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div><!-- End .cart-table-container -->
                    <div class="btn-actions" style="display: flex; align-items: center; justify-content: space-between;">
                        <button type="button" class="btn btn-dark" data-dismiss="modal">Folytatom a vásárlást</button>
                        <button type="button" @click="goToCart()" class="btn btn-primary">Tovább a kosárhoz
                        </button>
                    </div>
                    <hr style="margin: 30px auto">
                    <div class="row mt-2" v-if="relatedProducts">
                        <div class="col-sm-6" data-animation-name="fadeInLeftShorter" data-animation-delay="200">
                            <h2 class="h4 section-sub-title">{{ $trans('Related Products') }}</h2>
                            <div class="product-default left-details product-widget" v-for="product in relatedProducts.slice(0,2)"
                                 :key="product.id">
                                <figure>
                                    <a :href="'/termek/'+product.slug+'-p'+product.id">

                                        <img
                                            :src="product.mainImage+'?width=74&height=74'"
                                            width="74" height="74" :alt="product.name" loading="lazy"/>
                                    </a>
                                </figure>

                                <div class="product-details">
                                    <h3 class="product-title hide-text" :title="product.name"><a
                                        :href="'/termek/'+product.slug+'-p'+product.id">{{ product.name }}</a>
                                    </h3>

                                    <div class="price-box">
                                <span
                                    v-if="product.salePrice > 0 && product.salePrice !== product.price && product.salePrice < product.price"
                                    class="old-price">{{
                                        $priceFormatter.formatPriceText(product.grossPrice)
                                    }}</span>
                                        <span
                                            v-if="product.salePrice > 0 && product.salePrice !== product.price && product.salePrice < product.price"
                                            class="product-price">{{
                                                $priceFormatter.formatPriceText(product.grossSalePrice)
                                            }}</span>
                                        <span v-else class="product-price">{{
                                                $priceFormatter.formatPriceText(product.grossPrice)
                                            }}</span>

                                        <add-to-cart v-if="product.status === 'active'" :model="product" type="product"
                                                     variant="mini"></add-to-cart>
                                    </div><!-- End .price-box -->
                                </div><!-- End .product-details -->
                            </div>
                        </div>
                        <div class="col-sm-6" data-animation-name="fadeInLeftShorter" data-animation-delay="200">
                            <h2 class="h4 section-sub-title">&nbsp;</h2>
                            <div class="product-default left-details product-widget" v-for="product in relatedProducts.slice(2,4)"
                                 :key="product.id">
                                <figure>
                                    <a :href="'/termek/'+product.slug+'-p'+product.id">

                                        <img
                                            :src="product.mainImage+'?width=74&height=74'"
                                            width="74" height="74" :alt="product.name" loading="lazy"/>
                                    </a>
                                </figure>

                                <div class="product-details">
                                    <h3 class="product-title hide-text" :title="product.name"><a
                                        :href="'/termek/'+product.slug+'-p'+product.id">{{ product.name }}</a>
                                    </h3>

                                    <div class="price-box">
                                <span
                                    v-if="product.salePrice > 0 && product.salePrice !== product.price && product.salePrice < product.price"
                                    class="old-price">{{
                                        $priceFormatter.formatPriceText(product.grossPrice)
                                    }}</span>
                                        <span
                                            v-if="product.salePrice > 0 && product.salePrice !== product.price && product.salePrice < product.price"
                                            class="product-price">{{
                                                $priceFormatter.formatPriceText(product.grossSalePrice)
                                            }}</span>
                                        <span v-else class="product-price">{{
                                                $priceFormatter.formatPriceText(product.grossPrice)
                                            }}</span>

                                        <add-to-cart v-if="product.status === 'active'" :model="product" type="product"
                                                     variant="mini"></add-to-cart>
                                    </div><!-- End .price-box -->
                                </div><!-- End .product-details -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import portoLoadingOverlay from "./PortoLoadingOverlay.vue";
import {Modal} from "bootstrap";

export default {
    components: {portoLoadingOverlay},
    data() {
        return {
            loading: false,
            item: {},
            relatedProducts: [],
            quantity: 0,
        }
    },
    methods: {
        initData(item, relatedProducts, quantity) {
            this.item = item
            this.relatedProducts = relatedProducts
            this.quantity = quantity
            this.item.quantity = quantity
        },
        addOne() {
            if (this.item.associatedModel.minimumOrderMultiply) {
                this.quantity += parseInt(this.item.associatedModel.minimumOrderQuantity)
                this.item.quantity += parseInt(this.item.associatedModel.minimumOrderQuantity)
            } else {
                this.quantity++
                this.item.quantity++
            }
            this.updateCart()
        },
        removeOne() {
            if (this.quantity === 0) {
                return false;
            }

            if (this.item.associatedModel.minimumOrderMultiply) {
                this.quantity -= parseInt(this.item.associatedModel.minimumOrderQuantity)
                this.item.quantity -= parseInt(this.item.associatedModel.minimumOrderQuantity)
            } else {
                this.quantity--
                this.item.quantity--
            }

            if (this.quantity === 0) {
                document.getElementById('addedToCartModal').style.display = 'none'
                document.querySelector('.modal-backdrop').style.display = 'none'
            }

            this.updateCart()
        },
        removeFromCart() {

            let quantity = this.quantity

            this.loading = true
            this.$store.dispatch('removeFromCart', {
                id: this.item.associatedModel.id,
                type: this.item.associatedModel.modelType
            }).then(() => {
                let headerCart = this.$root.$refs["headerCart"];
                if(headerCart) {
                    headerCart.initData()
                }
                let mobileCartIcon = this.$root.$refs["mobileCartIcon"];
                if(mobileCartIcon) {
                    mobileCartIcon.initData()
                }
                Porto.miniPopup.open({
                    name: this.item.associatedModel.name,
                    nameLink: '/termek' + this.item.associatedModel.slug + '/-p' + this.item.associatedModel.id,
                    imageSrc: this.item.associatedModel.mainImage,
                    imageLink: '/termek' + this.item.associatedModel.slug + '/-p' + this.item.associatedModel.id,
                    content: this.$trans('has been removed from your cart.'),
                    action: '<a href="/kosar" class="btn viewcart">' + this.$trans('View cart') + '</a>',
                });

                let price = this.item.price * ((1 + this.item.associatedModel.taxRate) / 100)

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'remove_from_cart',
                    ecommerce: {
                        // TODO: ha lesz több valuta, tegyük dinamikussá
                        currency: 'HUF',
                        value: this.$priceFormatter.formatPriceNumber(quantity * price),
                        items: [
                            {
                                item_name: this.item.associatedModel.name,
                                price: this.$priceFormatter.formatPriceNumber(price),
                                item_category: this.item.associatedModel.category.name,
                                quantity: quantity
                            },
                        ]
                    }
                })

            }).catch(error => {
                if (error.response.status === 500) {
                    Porto.miniPopup.open({
                        name: 'Hiba!',
                        nameLink: '#',
                        imageSrc: '/img/error.png',
                        imageLink: '#',
                        content: error.response.data.message,
                        action: '',
                    });
                }
            }).finally(() => {
                this.loading = false
                document.getElementById('addedToCartModal').style.display = 'none'
                document.querySelector('.modal-backdrop').style.display = 'none'
            })
        },
        updateCart() {
            this.loading = true

            this.$store.dispatch('updateCart', {item: this.item}).then(() => {
                let headerCart = this.$root.$refs["headerCart"];
                if(headerCart) {
                    headerCart.initData()
                }
                let mobileCartIcon = this.$root.$refs["mobileCartIcon"];
                if(mobileCartIcon) {
                    mobileCartIcon.initData()
                }

                Porto.miniPopup.open({
                    name: this.$trans('Your cart has been updated.'),
                    nameLink: '#',
                    imageSrc: '/img/success.png',
                    imageLink: '#',
                    content: '',
                    action: '<a href="/kosar" class="btn viewcart">' + this.$trans('View cart') + '</a>',
                });
            }).catch(err => {
                Porto.miniPopup.open({
                    name: 'Hiba!',
                    nameLink: '#',
                    imageSrc: '/img/error.png',
                    imageLink: '#',
                    content: err.response.data.message,
                    action: '',
                });
                this.quantity = err.response.data.data.availableQuantity
                this.item.quantity = err.response.data.data.availableQuantity
            }).finally(() => {
                this.loading = false
            })
        },
        validateQuantity() {
            if (parseInt(this.quantity) > 0) {
                if (this.item.associatedModel.minimumOrderMultiply && parseInt(this.item.associatedModel.minimumOrderQuantity) > 1) {
                    if (parseInt(this.quantity) % parseInt(this.item.associatedModel.minimumOrderQuantity) !== 0) {
                        Porto.miniPopup.open({
                            name: 'Hiba!',
                            nameLink: '#',
                            imageSrc: '/img/error.png',
                            imageLink: '#',
                            content: 'A mennyiség csak a(z) ' + parseInt(this.item.associatedModel.minimumOrderQuantity) + ' többszöröse lehet.',
                            action: '',
                        });

                        this.quantity = this.roundNearest(this.quantity, this.item.associatedModel.minimumOrderQuantity)
                        this.item.quantity = this.roundNearest(this.quantity, this.item.associatedModel.minimumOrderQuantity)

                        return false
                    }
                }else {
                    this.item.quantity = this.quantity
                }
            }

            this.updateCart()

            return true
        },
        roundNearest(number, qty) {
            let rounded = Math.round(number / qty) * qty;
            if (rounded < qty) {
                rounded = parseInt(qty)
            }

            return rounded
        },
        goToCart() {
            window.location.href = '/kosar'
        }
    }
}
</script>
