const addressActions = {
    fetchAddress({commit, dispatch}, data) {
        return window.axios.get(`/address/${data.modelType}/${data.modelId}/${data.type}`)
    },
    fetchCountries() {
        return window.axios.get('countries')
    },
    updateAddress({commit, dispatch}, data) {
        return window.axios.post(`/address/${data.modelType}/${data.modelId}/${data.type}`, data)
    }
}

export default addressActions
