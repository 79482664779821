export default {
    formatPriceText(value) {
        let val = (value / 1).toFixed(0).replace('.', ',')
        let price = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        let space = ' ';

        return price+space+'Ft'
    },
    formatPriceNumber(value){
        let val = (value / 1).toFixed(0).replace('.', '')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '')
    }
}
