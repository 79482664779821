<template>
    <Form @submit="submitForm" :validation-schema="formSchema" v-slot="{ errors }">
        <porto-loading-overlay v-if="loading"></porto-loading-overlay>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group" :class="{'has-error': errors.name}">
                    <label>{{ $trans('Name') }} <span class="required">*</span></label>
                    <Field name="name" as="input" class="form-control" v-model="formData.name"/>
                    <ErrorMessage class="form-error" name="name"/>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="form-group" :class="{'has-error': errors.email}">
                    <label>{{ $trans('Email address') }} <span class="required">*</span></label>
                    <Field name="email" as="input" type="email" class="form-control" v-model="formData.email"/>
                    <ErrorMessage class="form-error" name="email"/>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="form-group" :class="{'has-error': errors.phone}">
                    <label>{{ $trans('Phone') }} <span class="required">*</span></label>
                    <Field name="phone" as="input" type="text" class="form-control"
                           v-model="formData.phone" />
                    <ErrorMessage class="form-error" name="phone"/>
                </div>
            </div>
        </div>

        <div class="row" v-if="type === 'billing'">
            <div class="col-md-12">
                <div class="form-group" :class="{'has-error': errors.taxNumber}">
                    <label>{{ $trans('Tax number') }}</label>
                    <Field ref="taxNumber" name="taxNumber" as="input" type="text" class="form-control"
                           v-model="formData.taxNumber" @input="queryTaxNumber" />
                    <ErrorMessage class="form-error" name="taxNumber"/>
                </div>
            </div>
        </div>

        <div class="row" v-if="type === 'billing'">
            <div class="col-md-12">
                <div class="form-group" :class="{'has-error': errors.companyName}">
                    <label>{{ $trans('Company name') }}</label>
                    <Field name="companyName" as="input" type="text" class="form-control"
                           v-model="formData.companyName"/>
                    <ErrorMessage class="form-error" name="companyName"/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>{{ $trans('Country') }} <span class="required">*</span></label>
            <VueMultiselect
                :model-value="formData.country"
                :options="countryOptions"
                :searchable="true"
                :close-on-select="true"
                :allow-empty="true"
                label="title"
                @update:model-value="updateCountry"
                :placeholder="$trans('Select one')"
                :select-label="$trans('Press enter to select')"
                :deselect-label="$trans('Press enter to remove')"
                :selected-label="$trans('Selected')"
                track-by="title"
            >
                <template #noResult>
                    {{ $trans('Oops! No elements found. Consider changing the search query.') }}
                </template>
            </VueMultiselect>
            <Field name="country" as="input" type="hidden" v-model="formData.country" />
            <ErrorMessage class="form-error" name="country"/>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="form-group" :class="{'has-error': errors.zip}">
                    <label>{{ $trans('Zip') }} <span class="required">*</span></label>
                    <Field name="zip" as="input" class="form-control" v-model="formData.zip"/>
                    <ErrorMessage class="form-error" name="zip"/>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="form-group" :class="{'has-error': errors.city}">
                    <label>{{ $trans('City') }} <span class="required">*</span></label>
                    <Field name="city" as="input" class="form-control" v-model="formData.city"/>
                    <ErrorMessage class="form-error" name="city"/>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="form-group" :class="{'has-error': errors.street}">
                    <label>{{ $trans('Street') }} <span class="required">*</span></label>
                    <Field name="street" as="input" class="form-control" v-model="formData.street"/>
                    <ErrorMessage class="form-error" name="street"/>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="form-group" :class="{'has-error': errors.houseNumber}">
                    <label>{{ $trans('House number') }} <span class="required">*</span></label>
                    <Field name="houseNumber" as="input" class="form-control" v-model="formData.houseNumber"/>
                    <ErrorMessage class="form-error" name="houseNumber"/>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="form-group" :class="{'has-error': errors.houseNumberInfo}">
                    <label>{{ $trans('House number info') }}</label>
                    <Field name="houseNumberInfo" as="input" class="form-control" v-model="formData.houseNumberInfo"/>
                    <ErrorMessage class="form-error" name="houseNumberInfo"/>
                </div>
            </div>
        </div>

        <div class="form-footer mb-0">
            <div class="form-footer-right">
                <button class="btn btn-dark py-4">
                    {{ $trans('Save Address') }}
                </button>
            </div>
        </div>
    </Form>
</template>
<script>
import {Form, Field, ErrorMessage} from "vee-validate";
import {registerLocale, getName as getCountryName} from 'i18n-iso-countries'
import hu from 'i18n-iso-countries/langs/hu.json'
import VueMultiselect from 'vue-multiselect'
import PortoLoadingOverlay from "./PortoLoadingOverlay.vue";
import {formatTaxNumber} from "../formatters/taxNumberFormatter";
import {setupVeeValidate} from "../utils/veeValidate";

export default {
    props: {
        modelType: {
            required: true
        },
        modelId: {
            required: true
        },
        type: {
            required: true
        },
    },
    components: {
        PortoLoadingOverlay,
        Form,
        Field,
        ErrorMessage,
        VueMultiselect
    },
    data() {
        return {
            formData: {
                name: '',
                email: '',
                phone: '',
                country: '',
                zip: '',
                city: '',
                street: '',
                houseNumber: '',
                houseNumberInfo: '',
                companyName: '',
                taxNumber: '',
            },
            formSchema: {
                name: {
                    required: true,
                },
                email: {
                    required: true,
                    email: true,
                },
                phone: {
                    required: true,
                    phone: true,
                },
                taxNumber: {
                    taxNumber: [this.selectedCountryCode]
                },
                country: {
                    required: true,
                },
                zip: {
                    required: true,
                },
                city: {
                    required: true,
                },
                street: {
                    required: true,
                },
                houseNumber: {
                    required: true,
                },
            },
            countryOptions: [],
            loading: false,
        }
    },
    computed: {
        selectedCountryCode(){
            return this.formData.country.iso2
        },
    },
    created() {
        setupVeeValidate();
        registerLocale(hu);
        this.loading = true
        this.$store.dispatch('fetchCountries').then(response => {
            Object.keys(response.data.entities).forEach(key => {
                let country = response.data.entities[key]
                let iso2 = country.iso2
                let name = getCountryName(iso2, hu.locale)

                if(iso2 === 'hu'){
                    this.formData.country = {
                        value: country.id,
                        title: name,
                        iso2: country.iso2
                    }
                }

                this.countryOptions.push({
                    value: country.id,
                    title: name,
                    iso2: country.iso2
                })
            })
        })
    },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            this.loading = true
            this.$store.dispatch('fetchAddress', {
                modelType: this.modelType,
                modelId: this.modelId,
                type: this.type,
            }).then(response => {

                let address = response.data.entity

                this.formData.name = address.name
                this.formData.email = address.email
                this.formData.phone = address.phone
                this.formData.taxNumber = address.taxNumber
                this.formData.companyName = address.companyName
                this.formData.zip = address.zip
                this.formData.city = address.city
                this.formData.street = address.street
                this.formData.houseNumber = address.houseNumber
                this.formData.houseNumberInfo = address.houseNumberInfo

            }).catch(error => {
                if (error.response.status === 404) {
                    this.$store.dispatch('initCurrentUser').then(() => {
                        let user = this.$store.getters.user

                        if (user) {
                            this.formData.name = user.name
                            this.formData.email = user.email
                        }
                    })
                }
            }).finally(() => {
                this.loading = false
            })
        },
        queryTaxNumber(){

            let countryCode = this.selectedCountryCode
            let taxNumber = formatTaxNumber(this.formData.taxNumber, countryCode.toUpperCase())

            this.formData.taxNumber = taxNumber

            this.$refs.taxNumber.validate().then(validated => {
                if(validated.valid){
                    this.loading = true
                    let actionName
                    let isoCode

                    // Ha Magyarország
                    if (countryCode === 'hu') {
                        actionName = 'getTaxNumberHu'
                        isoCode = ''
                    } else {
                        actionName = 'getTaxNumberEu';
                        isoCode = countryCode.toUpperCase()
                    }

                    this.$store.dispatch(actionName, {'taxNumber': isoCode+taxNumber}).then(response => {
                        let taxpayer = response.data.taxpayer

                        this.formData.companyName = taxpayer.name
                        this.formData.taxNumber = taxpayer.taxNumber
                        this.formData.zip = taxpayer.zip
                        this.formData.city = taxpayer.city
                        this.formData.street = taxpayer.street
                        this.formData.houseNumber = taxpayer.houseNumber
                        this.formData.houseNumberInfo = taxpayer.houseNumberInfo

                    }).catch(error => {
                        if (error.response.status === 422) {
                            this.clearAddressData()
                            // TODO: set errors
                        }
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            })
        },
        updateCountry(selectedOption){
            this.formData.country = selectedOption
        },
        clearAddressData(){
            this.formData.companyName = ''
            this.formData.zip = ''
            this.formData.city = ''
            this.formData.street = ''
            this.formData.houseNumber = ''
            this.formData.houseNumberInfo = ''
        },
        submitForm(values) {
            let fd = JSON.parse(JSON.stringify(this.formData))
            fd.country = this.formData.country.value
            fd.phone = this.formData.phone
            fd.modelType = this.modelType
            fd.modelId = this.modelId
            fd.type = this.type

            this.$store.dispatch('updateAddress', fd).then(() => {
                this.initData()
                Porto.miniPopup.open({
                    name: 'Siker!',
                    nameLink: '#',
                    imageSrc: '/img/success.png',
                    imageLink: '#',
                    content: 'A cím frissítése sikeres volt.',
                    action: '',
                });
            }).catch(error => {
                this.$refs.profileForm.setErrors(error.response.data.errors);
            }).finally(() => {

            })
        }
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
